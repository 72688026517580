import { createSlice } from "@reduxjs/toolkit";
import { changePassword } from "../thunk/changePasswordThunk";

const initialState = {
  changePasswordData: [],
  loading: false,
  success: false,
  error: null,
};

const changePasswordSlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    resetChangePassword: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.changePasswordData = action.payload;
        state.error = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetChangePassword } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
