import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { byIdCourse, editCourse } from "../../redux/thunk/courseThunk";
import { resetCourse } from "../../redux/slice/courseSlice";

const EditCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();

  const [description, setDescription] = useState([]);
  const [inputDesc, setInputDesc] = useState("");

  const [formFieldData, setFormFieldData] = useState({
    name: "",
    price: "",
    duration: "",
  });

  const { courseEdit, courseById, loading } = useSelector(
    (state) => state.courseState
  );
  useEffect(() => {
    dispatch(byIdCourse(slug));

    return () => {
      dispatch(resetCourse());
    };
  }, [slug, dispatch]);
  useEffect(() => {
    if (courseById) {
      setDescription(JSON.parse(courseById?.description || "[]"));
    }
  }, [courseById]);

  useEffect(() => {
    if (courseEdit.success) {
      navigate("/course", { replace: true });
      dispatch(resetCourse());
    }
  }, [courseEdit.success, dispatch, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  function handleDescChange(e) {
    setInputDesc(e.target.value);
  }

  function handleDescSubmit(e) {
    e.preventDefault();
    setDescription([...description, inputDesc]);
    setInputDesc("");
  }

  function handleDescDelete(index) {
    const newDescription = [...description];
    newDescription.splice(index, 1);
    setDescription(newDescription);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        name: formFieldData.name,
        price: formFieldData.price,
        duration: formFieldData.duration,
        description: JSON.stringify(description),
      };

      if (data) {
        dispatch(editCourse({ data: data, slug }));
      }
    } catch (err) {}
  };

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Edit Course </h1>
        <h1 className="addeditblog-blogtext2">Here you can Edit your course</h1>
        <form
          className="addeditblog-blogform"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="name" data-after=" *">
                Course Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter course section ..."
                defaultValue={courseById?.name}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="price" data-after=" *">
                Course Price
              </label>
              <input
                type="text"
                id="price"
                name="price"
                placeholder="eg. Rs 4000"
                defaultValue={courseById.price}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="duration" data-after=" *">
                Course Duration (/mo)
              </label>
              <input
                type="text"
                id="duration"
                name="duration"
                placeholder="eg. 2"
                defaultValue={courseById.duration}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="price" data-after=" *">
                Course Description
              </label>
              <div>
                <form className="descform">
                  <input
                    type="text"
                    placeholder="eg. IELTS-Listening Courses"
                    value={inputDesc}
                    onChange={handleDescChange}
                  />
                  <div>
                    <button
                      onClick={handleDescSubmit}
                      className="primary-button"
                    >
                      <i className="fa-regular fa-plus"></i>&nbsp;Add
                    </button>
                  </div>
                </form>
                {description.length > 0 && (
                  <table>
                    <thead>
                      <th>S.N</th>
                      <th>Description</th>
                      <th>Action</th>
                    </thead>

                    <tbody>
                      {description.map((value, index) => (
                        <tr>
                          <td style={{ textAlign: "center", color: "green" }}>
                            <i className="fa-regular fa-check"></i>
                          </td>
                          <td> {value}</td>
                          <td style={{ textAlign: "center", color: "red" }}>
                            <i
                              class="fa-regular fa-trash"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDescDelete(index)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Edit Course "
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditCourse;
