import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet } from "../../axios/instance";
import { toast } from "react-toastify";

export const getPrivateSession = createAsyncThunk(
  "getPrivateSession",
  async () => {
    try {
      const response = await doGet("/admin/private-session");
      if (response.status === 200) {
        return response.data.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);
export const getByIdPrivateSession = createAsyncThunk(
  "getByIdPrivateSession",
  async (id) => {
    try {
      const response = await doGet(`/admin/private-session/${id}`);
      if (response.status === 200) {
        return response.data.data[0];
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const deletePrivateSession = createAsyncThunk(
  "deletePrivateSession",
  async (id) => {
    try {
      const response = await doDelete(`/admin/private-session/${id}`);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);
