import React from "react";

const Loader = ({ isActive }) => {
  return (
    <div className={`loader ${isActive ? "active" : ""}`}>
      <div className="loader-element"></div>
    </div>
  );
};

export default Loader;
