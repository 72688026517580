import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../utils/env";

export const loginAdmin = createAsyncThunk("loginAdmin", async (data) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    const response = await axios.post(`${baseUrl}/admin/login`, data, config);
    if (response.status === 200) {
      const { accessToken } = response.data.data;
      localStorage.setItem("siliconToken", accessToken);
      toast.success("Logged in Successfully", {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Login failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message);
    throw error;
  }
});
