import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { resetVideo } from "../../redux/slice/videoSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formats, modules } from "../../components/quill";
import { getCourse } from "../../redux/thunk/courseThunk";
import { getSeries } from "../../redux/thunk/seriesThunk";
import { addSection, getSection } from "../../redux/thunk/sectionThunk";
import { editVideo, getByIdVideo } from "../../redux/thunk/videoThunk";
import { resetSection } from "../../redux/slice/sectionSlice";
import { url } from "../../utils/env";
import { authorAdmin } from "../../redux/thunk/authorThunk";
const EditVideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { course_slug, series_slug, slug } = useParams();
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSeries, setSelectedSeries] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedVideoType, setSelectedVideoType] = useState("");
  const [showAddSection, setShowAddSection] = useState(false);
  const [sectionName, setSectionName] = useState("");

  const [thumbnail, setThumbnail] = useState({
    file: null,
    dataURL: null,
  });
  const [video, setVideo] = useState({
    file: null,
    dataURL: null,
  });

  const [description, setDescription] = useState("");
  const [formFieldData, setFormFieldData] = useState({
    title: "",
  });
  const { author } = useSelector((state) => state.authorState);
  useEffect(() => {
    dispatch(authorAdmin());
  }, [dispatch]);
  const { videoEdit, videoById, loading } = useSelector(
    (state) => state.videoState
  );
  const {
    section,
    sectionAdd,
    loading: sectionLoading,
  } = useSelector((state) => state.sectionState);
  const { course, loading: courseLoading } = useSelector(
    (state) => state.courseState
  );
  const { series, loading: seriesLoading } = useSelector(
    (state) => state.seriesState
  );

  useEffect(() => {
    dispatch(getByIdVideo(slug));
    return () => {
      dispatch(resetVideo());
    };
  }, [slug, dispatch]);
  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSeries());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSection());
  }, [dispatch]);
  useEffect(() => {
    if (course_slug) {
      setSelectedCourse(course_slug);
    }
  }, [course_slug]);
  useEffect(() => {
    if (series_slug) {
      setSelectedSeries(series_slug);
    }
  }, [series_slug]);

  useEffect(() => {
    if (videoById?.section_slug) {
      setSelectedSection(videoById?.section_slug);
    }
  }, [videoById?.section_slug]);

  useEffect(() => {
    if (videoById?.flag) {
      setSelectedVideoType(videoById?.flag);
    }
  }, [videoById?.flag]);

  useEffect(() => {
    if (videoById?.author_id) {
      setSelectedAuthor(videoById?.author_id);
    }
  }, [videoById?.author_id]);

  useEffect(() => {
    if (videoById.description) {
      setDescription(videoById.description);
    }
  }, [videoById.description]);

  const handleImageChange = (setFunction, event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFunction({
          file: file,
          dataURL: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("course_slug", course_slug);
      formData.append("series_slug", series_slug);
      formData.append("section_slug", selectedSection);
      formData.append("title", formFieldData.title);
      formData.append("image", thumbnail?.file);
      formData.append("description", description);
      formData.append("video", video?.file);
      formData.append("flag", selectedVideoType);
      formData.append("author_id", selectedAuthor);
      if (formData) {
        dispatch(editVideo({ data: formData, slug }));
      }
    } catch (err) {}
  };

  const handleAddSectionSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        course_slug: course_slug,
        series_slug: series_slug,
        name: sectionName,
      };

      if (data) {
        dispatch(addSection(data));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (videoEdit.success) {
      navigate(`/${course_slug}/${series_slug}`);
      dispatch(resetVideo());
    }
  }, [videoEdit.success, dispatch, navigate, course_slug, series_slug]);

  useEffect(() => {
    if (sectionAdd.success) {
      dispatch(resetSection());
      dispatch(getSection());
      setShowAddSection(false);
      setSectionName("");
    }
  }, [sectionAdd.success, dispatch]);

  const filteredSection = useMemo(() => {
    return section.filter(
      (item) =>
        item.course_slug === course_slug && item.series_slug === series_slug
    );
  }, [section, course_slug, series_slug]);
  return (
    <Layout>
      {(loading || courseLoading || sectionLoading || seriesLoading) && (
        <Loader isActive={true} />
      )}
      <div className="addeditblog">
        <h1 className="addeditblog-blogtext1">Edit Video</h1>
        <h1 className="addeditblog-blogtext2">
          Here you can edit your series video
        </h1>
        <form
          className="addeditblog-blogform"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="course_slug" data-after=" *">
                Course Name
              </label>
              <select
                name="course_slug"
                id="course_slug"
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
                className="customselect"
              >
                <option value="">-- Select Course Name --</option>
                {course?.map((value, i) => (
                  <option value={value.slug} key={i}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="series_slug" data-after=" *">
                Series Name
              </label>
              <select
                name="series_slug"
                id="series_slug"
                value={selectedSeries}
                onChange={(e) => setSelectedSeries(e.target.value)}
                className="customselect"
              >
                <option value="">-- Select Series Name --</option>
                {series
                  ?.filter((s) => s.course_slug === selectedCourse)
                  .map((value, i) => (
                    <option value={value.slug} key={i}>
                      {value.name}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <label htmlFor="section_slug" data-after=" *">
                Section Name
              </label>
              <div>
                <select
                  name="section_slug"
                  id="section_slug"
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}
                  className="customselect"
                >
                  <option value="">-- Select Section Name --</option>
                  {filteredSection?.map((value, i) => (
                    <option value={value.slug} key={i}>
                      {value.name}
                    </option>
                  ))}
                </select>
                <div
                  style={{
                    paddingBottom: "0.5rem",
                    cursor: "pointer",
                    color: "#ff4500",
                  }}
                  onClick={() => setShowAddSection(!showAddSection)}
                >
                  Add New Section
                </div>
                {showAddSection && (
                  <form className="descform">
                    <input
                      type="text"
                      id="author_name"
                      name="author_name"
                      placeholder="eg. Anil Shrestha"
                      value={sectionName}
                      onChange={(e) => setSectionName(e.target.value)}
                    />
                    <div>
                      <button
                        onClick={handleAddSectionSubmit}
                        className="primary-button"
                      >
                        <i className="fa-regular fa-plus"></i>&nbsp;Add
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>

            <div>
              <label htmlFor="author_name" data-after=" *">
                Author Name
              </label>
              <div>
                <select
                  name="author_name"
                  id="author_name"
                  value={selectedAuthor}
                  onChange={(e) => setSelectedAuthor(e.target.value)}
                  className="customselect"
                >
                  <option value="">-- Select Author Name --</option>
                  {author?.map((value, i) => (
                    <option value={value.author_id} key={i}>
                      {value.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label htmlFor="flag" data-after=" *">
                Video Type
              </label>
              <div className="radioinputdiv">
                <div className="radioinputdiv-radioinput">
                  <input
                    type="radio"
                    name="flag"
                    id="Free"
                    value="Free"
                    checked={selectedVideoType === "Free"}
                    onChange={(e) => {
                      setSelectedVideoType(e.target.value);
                    }}
                  />
                  <label htmlFor="Free">Free</label>
                </div>
                <div className="radioinputdiv-radioinput">
                  <input
                    type="radio"
                    name="flag"
                    id="Premium"
                    value="Premium"
                    checked={selectedVideoType === "Premium"}
                    onChange={(e) => {
                      setSelectedVideoType(e.target.value);
                    }}
                  />
                  <label htmlFor="Premium">Premium</label>
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="description" data-after=" *">
                Description
              </label>
              <ReactQuill
                style={{ paddingTop: "1rem" }}
                theme="snow"
                value={description}
                onChange={setDescription}
                modules={modules}
                formats={formats}
              />
            </div>
          </div>

          <div className="addeditblog-blogform--right">
            <div>
              <label htmlFor="title" data-after=" *">
                Title
              </label>
              <input
                type="text"
                id="itle"
                name="title"
                placeholder="eg. Intro to IELTS"
                defaultValue={videoById?.title}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="thumbnail" data-after=" *">
                Thumbnail
              </label>
              <input
                type="file"
                id="thumbnail"
                name="thumbnail"
                accept="image/*"
                onChange={(e) => handleImageChange(setThumbnail, e)}
              />
              {thumbnail?.dataURL ? (
                <img
                  src={thumbnail?.dataURL}
                  alt="Selected"
                  style={{
                    width: "100%",
                    height: "250px",
                    margin: "10px 0px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={`${url}/video/${videoById?.video_thumbnail}`}
                  alt="Select"
                  style={{
                    width: "100%",
                    height: "250px",
                    margin: "10px 0px",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div>
              <label htmlFor="video" data-after=" *">
                Video
              </label>
              <input
                type="file"
                id="video"
                name="video"
                onChange={(e) => handleImageChange(setVideo, e)}
              />

              {video?.dataURL ? (
                <div style={{ marginBottom: "1rem" }}>
                  <video
                    src={video?.dataURL}
                    alt="Selected"
                    width="100%"
                    height="320"
                    controls
                  >
                    <source src={video?.dataURL} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <div style={{ marginBottom: "1rem" }}>
                  <video
                    src={`${url}/video/${videoById?.video}`}
                    alt="Selected"
                    width="100%"
                    height="320"
                    controls
                  >
                    <source src={video?.dataURL} type="video/mp4" />
                  </video>
                </div>
              )}
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Edit Video"
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditVideo;
