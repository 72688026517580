import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { getCourseWithSeries } from "../redux/thunk/courseThunk";
import { setActiveCourse } from "../redux/slice/navlinkSlice";
import { toggleCourseClick } from "../redux/slice/courseClickSlice";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const activeCourse = useSelector((state) => state.navlinkState.activeCourse);
  const isCourseClick = useSelector(
    (state) => state.courseClickState.isCourseClick
  );
  const { courseWithSeriesData } = useSelector((state) => state.courseState);
  useEffect(() => {
    dispatch(getCourseWithSeries());
  }, [dispatch]);

  const isActiveBlog =
    location.pathname.startsWith("/blog") ||
    location.pathname.startsWith("/addblog") ||
    location.pathname.startsWith("/editblog");

  const isActiveAuthor =
    location.pathname.startsWith("/author") ||
    location.pathname.startsWith("/addauthor") ||
    location.pathname.startsWith("/editauthor");

  const isActiveUser =
    location.pathname.startsWith("/user") ||
    location.pathname.startsWith("/adduser");

  const isActiveMembership =
    location.pathname.startsWith("/membership") ||
    location.pathname.startsWith("/addmembership") ||
    location.pathname.startsWith("/editmembership");

  const isActiveCourse =
    location.pathname.startsWith("/course") ||
    location.pathname.startsWith("/addcourse") ||
    location.pathname.startsWith("/editcourse");

  const handleCourseClick = (courseSlug) => {
    dispatch(setActiveCourse(courseSlug === activeCourse ? null : courseSlug));
  };

  const getSeriesIcon = (seriesName) => {
    const iconMappings = {
      Listening: "fa-headphones",
      Speaking: "fa-microphone",
      Writing: "fa-pencil-alt",
      Reading: "fa-book",
    };
    return iconMappings[seriesName] || "fa-list-music";
  };
  return (
    <div className="side">
      <div className="sidebarheader ">
        <h2>FREE</h2>
        <h2>IELTS & PTE</h2>
      </div>

      <div className="navigation">
        <div>
          <NavLink
            className={`navigation-link ${
              location.pathname === "/dashboard" ? "active" : ""
            }`}
            to="/dashboard"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-house icon`}></i>
              <span>Dashboard</span>
            </div>
          </NavLink>
        </div>
        <div>
          <NavLink
            className={`navigation-link ${isActiveUser ? "active" : ""}`}
            to="/user"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-users icon`}></i>
              <span>User</span>
            </div>
          </NavLink>
        </div>
        <div>
          <NavLink
            className={`navigation-link ${isActiveAuthor ? "active" : ""}`}
            to="/author"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-user icon`}></i>
              <span>Author</span>
            </div>
          </NavLink>
        </div>
        <div onClick={() => dispatch(toggleCourseClick())}>
          <NavLink
            className={`navigation-link ${isActiveCourse ? "active" : ""}`}
            to="/course"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-closed-captioning icon`}></i>
              <span>Course</span>
            </div>
          </NavLink>
        </div>
        {isCourseClick && (
          <>
            {courseWithSeriesData?.map((course) => (
              <div key={course.slug}>
                <div
                  className={`navigation-link `}
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() => handleCourseClick(course.slug)}
                >
                  <div className="navigation-link--items">
                    <i className={`fa-regular fa-language icon`}></i>
                    <span>{course.name}</span>
                  </div>
                  {course?.series && (
                    <i className={`fa-regular fa-chevron-right chevron`}></i>
                  )}
                </div>

                {activeCourse === course.slug && (
                  <div className="navigations">
                    {course.series.map((series) => (
                      <NavLink
                        key={series.slug}
                        className={`navigation-link ${
                          location.pathname === `${course.slug}/${series.slug}`
                            ? "active"
                            : ""
                        }`}
                        to={`/${course.slug}/${series.slug}`}
                        style={{ marginLeft: "1rem" }}
                      >
                        <div className="navigation-link--items">
                          <i
                            className={`fa-regular ${getSeriesIcon(
                              series.name
                            )} icon`}
                          ></i>
                          <span>{series.name}</span>
                        </div>
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </>
        )}

        {/* <div>
          <NavLink
            className={`navigation-link ${isActiveMembership ? "active" : ""}`}
            to="/membership"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-credit-card icon`}></i>
              <span>Memberships</span>
            </div>
          </NavLink>
        </div> */}

        <div>
          <NavLink
            className={`navigation-link ${
              location.pathname === "/subscription" ? "active" : ""
            }`}
            to="/subscription"
          >
            <div className="navigation-link--items">
              <i className="fa-regular fa-cart-circle-check icon"></i>
              <span>Subscriptions</span>
            </div>
          </NavLink>
        </div>

        <div>
          <NavLink
            className={`navigation-link ${
              location.pathname === "/private-session" ? "active" : ""
            }`}
            to="/private-session"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-screen-users icon`}></i>
              <span>Private Session</span>
            </div>
          </NavLink>
        </div>

        <div>
          <NavLink
            className={`navigation-link ${isActiveBlog ? "active" : ""}`}
            to="/blog"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-blog icon`}></i>
              <span>Blog</span>
            </div>
          </NavLink>
        </div>
        <div>
          <NavLink
            className={`navigation-link ${
              location.pathname === "/query" ? "active" : ""
            }`}
            to="/query"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-circle-question icon`}></i>
              <span>Query</span>
            </div>
          </NavLink>
        </div>

        <div>
          <NavLink
            className={`navigation-link ${
              location.pathname === "/notification" ? "active" : ""
            }`}
            to="/notification"
          >
            <div className="navigation-link--items">
              <i className={`fa-regular fa-bell icon`}></i>
              <span>Notification</span>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
