import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet } from "../../axios/instance";
import { toast } from "react-toastify";

export const getSubscription = createAsyncThunk("getSubscription", async () => {
  try {
    const response = await doGet("/admin/order");
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
export const getByIdSubscription = createAsyncThunk(
  "getByIdSubscription",
  async (id) => {
    try {
      const response = await doGet(`/admin/order/${id}`);
      if (response.status === 200) {
        return response.data.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);
