import { createSlice } from "@reduxjs/toolkit";
import {
  getByIdSubscription,
  getSubscription,
} from "../thunk/subscriptionThunk";

const initialState = {
  subscriptionData: [],
  subscriptionByIdData: [],
  loading: false,
  success: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetSubscription: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscription.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.subscriptionData = action.payload;
        state.error = null;
      })
      .addCase(getSubscription.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(getByIdSubscription.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getByIdSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.subscriptionByIdData = action.payload;
        state.error = null;
      })
      .addCase(getByIdSubscription.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
