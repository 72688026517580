import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import Layout from "../../components/Layout";
import Modal from "../../components/Modal";
import {
  deletePrivateSession,
  getByIdPrivateSession,
  getPrivateSession,
} from "../../redux/thunk/privateSessionThunk";
import { resetPrivateSession } from "../../redux/slice/privateSessionSlice";
import Loader from "../../components/loader/Loader";

const ListPrivateSession = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { privateSession, privateSessionDelete, privateSessionById, loading } =
    useSelector((state) => state.privateSessionState);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    dispatch(getPrivateSession());
  }, [dispatch]);
  useEffect(() => {
    if (privateSessionDelete.success) {
      setShowDeleteModal(false);
      navigate("/private-session");
      dispatch(resetPrivateSession());
    }
    dispatch(getPrivateSession());
  }, [privateSessionDelete.success, dispatch, navigate]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);
  useEffect(() => {
    if (showDeleteModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showDeleteModal]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Full Name",
        accessor: (row) => row.name,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Booked Date",
        accessor: (row) => moment(row.date).format("MMM Do YYYY"),
      },

      {
        Header: "Received At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              onClick={() => {
                dispatch(getByIdPrivateSession(row.id));
                setShowModal(true);
              }}
              className="actionlinks-view"
            >
              <i className="fa-regular fa-eye"></i>
            </div>
            <div
              onClick={() => {
                dispatch(getByIdPrivateSession(row.id));
                setShowDeleteModal(true);
              }}
              className="actionlinks-delete"
            >
              <i className="fa-regular fa-trash"></i>
            </div>
          </div>
        ),
      },
    ],
    [dispatch]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => privateSession, [privateSession]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="listpage">
        <div className="listpage-top">
          <h1>Private Session</h1>
          <div className="listpage-top--textlink">
            <h2>
              <span>
                <i className="fa-regular fa-screen-users"></i>
              </span>
              &nbsp;/ Private Session Management
            </h2>
            <div className="listpage-top--textlink_addsetting"></div>
          </div>
        </div>

        <div className="listpage-searchentry">
          <div className="listpage-searchentry--search">
            <input
              type="search"
              placeholder="Search here..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setGlobalFilter(e.target.value);
              }}
            />
            <i className="fa-regular fa-magnifying-glass"></i>
          </div>

          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="listpage-searchentry--pageselect"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={privateSession.length}>All</option>
          </select>
        </div>

        <div className="listpage-tablesection">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} style={column.headerStyle}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="notfound">
                    Data not found.
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {pageSize !== privateSession.length && (
            <div className="listpage-tablesection--pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          title={`Private Session by ${privateSessionById?.name}`}
          handleCloseModal={() => setShowModal(false)}
        >
          <div className="modalcontent" style={{ width: "500px" }}>
            <p>
              <strong>Name: </strong> {privateSessionById?.name}
            </p>
            <p>
              <strong>Email: </strong>
              {privateSessionById?.email}
            </p>
            <p>
              <strong>Phone: </strong>
              {privateSessionById?.phone}
            </p>
            <p>
              <strong>Booked Date: </strong>
              {moment(privateSessionById?.date).format("MMM Do YYYY")}
            </p>
            <p>
              <strong>Lesson: </strong>
              {privateSessionById?.lesson}
            </p>
            <p>
              <strong>Target Score: </strong>
              {privateSessionById?.score}
            </p>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Delete Private Session"
          handleCloseModal={() => setShowDeleteModal(false)}
        >
          <div className="confirmdiv" style={{ width: "400px" }}>
            <i
              className="fa-regular fa-circle-exclamation"
              style={{ background: "none" }}
            ></i>
            <p>Are you sure you want to delete this private session?</p>
            <div className="confirmdiv-btns">
              <button
                className="primary-button"
                onClick={() =>
                  dispatch(deletePrivateSession(privateSessionById?.id))
                }
              >
                Yes
              </button>
              <button
                className="secondary-button"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default ListPrivateSession;
