import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";

import Layout from "../../components/Layout";

import {
  byIdMembership,
  deleteMembership,
  editMembershipDeal,
  getMembership,
} from "../../redux/thunk/membershipThunk";
import { resetMembership } from "../../redux/slice/membershipSlice";
import Modal from "../../components/Modal";
import { getCourse } from "../../redux/thunk/courseThunk";
import Loader from "../../components/loader/Loader";

const ListMembership = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    membership,
    membershipDelete,
    membershipById,
    membershipDealEdit,
    loading,
  } = useSelector((state) => state.membershipState);

  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deal, setDeal] = useState("");
  const [showDealModal, setShowDealModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    if (membershipDelete.success) {
      setShowDeleteModal(false);
      navigate(`/membership`);
      dispatch(resetMembership());
    }
    dispatch(getMembership());
  }, [membershipDelete.success, dispatch, navigate]);

  useEffect(() => {
    dispatch(getMembership());
  }, [dispatch]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);
  useEffect(() => {
    if (showDeleteModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showDeleteModal]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
        headerStyle: {
          width: "100px",
        },
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Price",
        accessor: (row) =>
          row.deal ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  opacity: "0.8",
                  textDecoration: "line-through",
                }}
              >
                Rs {row.price}
              </div>
              <div>Rs {row.price - (row.deal * row.price) / 100}</div>
            </div>
          ) : (
            <div>Rs {row.price}</div>
          ),
        headerStyle: {
          width: "100px",
        },
      },
      {
        Header: "Deal",
        accessor: (row) => (
          <div className="statusdiv">
            <span
              style={{
                backgroundColor: getStatusBackgroundColor(row?.deal),
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(byIdMembership(row.slug));
                setShowDealModal(true);
              }}
              className="statusdiv-status"
            >
              {row.deal ? row.deal + "%" : <>Start Now</>}
            </span>
          </div>
        ),
        headerStyle: {
          width: "100px",
        },
      },
      {
        Header: "Created At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "250px",
        },
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              onClick={() => {
                setShowModal(true);
                dispatch(byIdMembership(row.slug));
              }}
              className="actionlinks-view"
            >
              <i className="fa-regular fa-eye"></i>
            </div>
            <Link
              to={`/editmembership/${row.slug}`}
              className="actionlinks-edit"
            >
              <i className="fa-regular fa-edit"></i>
            </Link>

            <div
              onClick={() => {
                dispatch(byIdMembership(row.slug));
                setShowDeleteModal(true);
              }}
              className="actionlinks-delete"
            >
              <i className="fa-regular fa-trash"></i>
            </div>
          </div>
        ),
        headerStyle: {
          width: "200px",
        },
      },
    ],
    [dispatch]
  );

  const getStatusBackgroundColor = (flag) => {
    if (!flag) {
      return "green";
    } else if (flag) {
      return "#fe0000";
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => membership, [membership]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        deal: deal,
      };

      if (data) {
        const slug = membershipById?.slug;
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(editMembershipDeal({ data: data, slug }));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (membershipDealEdit.success) {
      navigate(`/membership`);
      dispatch(resetMembership());
      setShowDealModal(false);
      setDeal("");
    }
    dispatch(getMembership());
  }, [membershipDealEdit.success, dispatch, navigate]);

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="listpage">
        <div className="listpage-top">
          <h1>Membership </h1>
          <div className="listpage-top--textlink">
            <h2>
              <span>
                <i className="fa-regular fa-credit-card"></i>
              </span>
              &nbsp;/ Membership Management
            </h2>
            <div className="listpage-top--textlink_addsetting">
              <Link className="secondary-button" to={`/addmembership`}>
                <span>
                  <i className="fa-regular fa-plus"></i>
                </span>
                &nbsp;&nbsp; Add Membership
              </Link>
            </div>
          </div>
        </div>

        <div className="listpage-searchentry">
          <div className="listpage-searchentry--search">
            <input
              type="search"
              placeholder="Search here..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setGlobalFilter(e.target.value);
              }}
            />
            <i className="fa-regular fa-magnifying-glass"></i>
          </div>

          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="listpage-searchentry--pageselect"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={membership.length}>All</option>
          </select>
        </div>

        <div className="listpage-tablesection">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} style={column.headerStyle}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="notfound">
                    Data not found.
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {pageSize !== membership.length && (
            <div className="listpage-tablesection--pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          title={`Membership Detail`}
          handleCloseModal={() => setShowModal(false)}
        >
          <div className="coursecontent" style={{ width: "300px" }}>
            <div className="coursecontent-name">{membershipById?.name}</div>
            <hr />
            <div className="coursecontent-price">
              Rs {membershipById?.price}
            </div>
            <div className="coursecontent-desc">
              {membershipById?.description}
            </div>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Delete Membership "
          handleCloseModal={() => setShowDeleteModal(false)}
        >
          <div className="confirmdiv" style={{ width: "400px" }}>
            <i
              className="fa-regular fa-circle-exclamation"
              style={{ background: "none" }}
            ></i>
            <p>Are you sure you want to delete this membership?</p>
            <div className="confirmdiv-btns">
              <button
                className="primary-button"
                onClick={() => dispatch(deleteMembership(membershipById?.slug))}
              >
                Yes
              </button>
              <button
                className="secondary-button"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showDealModal && (
        <Modal
          title="Membership Deal"
          handleCloseModal={() => setShowDealModal(false)}
        >
          <form onSubmit={handleSubmit} style={{ width: "400px" }}>
            <div>
              <label htmlFor="deal" data-after=" *">
                Configure Course Deal (%)
              </label>
              <input
                type="text"
                id="deal"
                name="deal"
                placeholder="eg. 10"
                defaultValue={membershipById?.deal}
                // value={deal}
                onChange={(e) => setDeal(e.target.value)}
              />
            </div>
            <div className="submitbtn">
              <input
                type="submit"
                value="Apply Percentage"
                className="secondary-button"
              />
            </div>
          </form>
        </Modal>
      )}
    </Layout>
  );
};

export default ListMembership;
