import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPost, doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const authorAdmin = createAsyncThunk("authorAdmin", async () => {
  try {
    const response = await doGet("/admin/author");
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
export const authorByIdAdmin = createAsyncThunk(
  "authorByIdAdmin",
  async (id) => {
    try {
      const response = await doGet(`/admin/author/${id}`);
      if (response.status === 200) {
        return response.data.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);
export const authorAddAdmin = createAsyncThunk(
  "authorAddAdmin",
  async (data) => {
    try {
      const response = await doPost(`/admin/author`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const authorEditAdmin = createAsyncThunk(
  "authorEditAdmin",
  async ({ data, id }) => {
    try {
      const response = await doPut(`/admin/author/${id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const authorDeleteAdmin = createAsyncThunk(
  "authorDeleteAdmin",
  async (id) => {
    try {
      const response = await doDelete(`/admin/author/${id}`);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);
