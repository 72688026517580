import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { baseUrl } from "../utils/env";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use((req) => {
  const accessToken = localStorage.getItem("siliconToken");
  if (req.url !== "/") {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }
  return req;
});

instance.interceptors.response.use(null, async (error) => {
  const { status } = error.response;
  if ([404, 409, 401, 400, 500].includes(status)) {
    throw error;
  } else {
    setTimeout(() => {
      localStorage.removeItem("siliconToken");
      window.location.href = "/";
    }, 2000);
  }
  throw error;
});

export const doGet = (uri, params = {}) => instance.get(uri, params);
/**
 *
 * @param {string} uri
 * @param {any} params
 * @param {AxiosRequestConfig<any>} options
 * @returns {Promise<AxiosResponse<any, any>>}
 */
export const doPost = (uri, params = {}, options = {}) =>
  instance.post(uri, params, options);
/**
 *
 * @param {string} uri
 * @param {any} params
 * @param {AxiosRequestConfig<any>} options
 * @returns {Promise<AxiosResponse<any, any>>}
 */
export const doPut = (uri, params = {}, options = {}) =>
  instance.put(uri, params, options);
export const doDelete = (uri, params = {}) => instance.delete(uri, params);
