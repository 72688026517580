import { createSlice } from "@reduxjs/toolkit";
import {
  addNotification,
  deleteNotification,
  editNotification,
  getByIdNotification,
  getNotification,
} from "../thunk/notificationThunk";

const initialState = {
  notification: [],
  notificationDelete: [],
  notificationById: [],
  notificationAdd: [],
  notificationEdit: [],
  loading: false,
  success: false,
  error: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetNotification: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notification = action.payload;
        state.error = null;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notificationDelete = action.payload;
        state.error = null;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(getByIdNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getByIdNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notificationById = action.payload;
        state.error = null;
      })
      .addCase(getByIdNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notificationAdd = action.payload;
        state.error = null;
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editNotification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.notificationEdit = action.payload;
        state.error = null;
      })
      .addCase(editNotification.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
