import { createSlice } from "@reduxjs/toolkit";
import {
  blogAddAdmin,
  blogAdmin,
  blogByIdAdmin,
  blogDeleteAdmin,
  blogEditAdmin,
} from "../thunk/blogThunk";

const initialState = {
  blog: [],
  blogDelete: [],
  blogById: [],
  blogAdd: [],
  blogEdit: [],
  loading: false,
  success: false,
  error: null,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    resetBlog: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(blogAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(blogAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.blog = action.payload;
        state.error = null;
      })
      .addCase(blogAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(blogDeleteAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(blogDeleteAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.blogDelete = action.payload;
        state.error = null;
      })
      .addCase(blogDeleteAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(blogByIdAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(blogByIdAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.blogById = action.payload;
        state.error = null;
      })
      .addCase(blogByIdAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(blogAddAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(blogAddAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.blogAdd = action.payload;
        state.error = null;
      })
      .addCase(blogAddAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(blogEditAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(blogEditAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.blogEdit = action.payload;
        state.error = null;
      })
      .addCase(blogEditAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetBlog } = blogSlice.actions;
export default blogSlice.reducer;
