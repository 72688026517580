import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeCourse: null,
};

const navlinkSlice = createSlice({
  name: "navlink",
  initialState,
  reducers: {
    setActiveCourse: (state, action) => {
      state.activeCourse = action.payload;
    },
  },
});

export const { setActiveCourse } = navlinkSlice.actions;
export default navlinkSlice.reducer;
