import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPost, doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const getSeries = createAsyncThunk("getSeries", async () => {
  try {
    const response = await doGet("/admin/series");
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const addSeries = createAsyncThunk("addSeries", async (data) => {
  try {
    const response = await doPost(`/admin/series`, data);
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const editSeries = createAsyncThunk(
  "editSeries",
  async ({ data, slug }) => {
    try {
      const response = await doPut(`/admin/series/${slug}`, data);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const editSeriesDeal = createAsyncThunk(
  "editSeriesDeal",
  async ({ data, slug }) => {
    try {
      const response = await doPut(`/admin/series/deal/${slug}`, data);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const deleteSeries = createAsyncThunk("deleteSeries", async (slug) => {
  try {
    const response = await doDelete(`/admin/series/${slug}`);
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
export const byIdSeries = createAsyncThunk("byIdSeries", async (slug) => {
  try {
    const response = await doGet(`/admin/series/${slug}`);
    if (response.status === 200) {
      return response.data.data[0];
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
