import { createAsyncThunk } from "@reduxjs/toolkit";
import { doGet } from "../../axios/instance";
import { toast } from "react-toastify";

export const getDashboard = createAsyncThunk("getDashboard", async () => {
  try {
    const response = await doGet("/admin/dashboard");
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
