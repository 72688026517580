import { createSlice } from "@reduxjs/toolkit";
import {
  authorAddAdmin,
  authorAdmin,
  authorByIdAdmin,
  authorDeleteAdmin,
  authorEditAdmin,
} from "../thunk/authorThunk";

const initialState = {
  author: [],
  authorDelete: [],
  authorById: [],
  authorAdd: [],
  authorEdit: [],
  loading: false,
  success: false,
  error: null,
};

const authorSlice = createSlice({
  name: "author",
  initialState,
  reducers: {
    resetAuthor: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(authorAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.author = action.payload;
        state.error = null;
      })
      .addCase(authorAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(authorDeleteAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(authorDeleteAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.authorDelete = action.payload;
        state.error = null;
      })
      .addCase(authorDeleteAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(authorByIdAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(authorByIdAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.authorById = action.payload;
        state.error = null;
      })
      .addCase(authorByIdAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(authorAddAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(authorAddAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.authorAdd = action.payload;
        state.error = null;
      })
      .addCase(authorAddAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(authorEditAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(authorEditAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.authorEdit = action.payload;
        state.error = null;
      })
      .addCase(authorEditAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetAuthor } = authorSlice.actions;
export default authorSlice.reducer;
