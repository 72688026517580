import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPost, doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const getUser = createAsyncThunk("getUser", async () => {
  try {
    const response = await doGet("/admin/user");
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const addUser = createAsyncThunk("addUser", async (data) => {
  try {
    const response = await doPost("/admin/user", data);
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const editUserBlockStatus = createAsyncThunk(
  "editUserBlockStatus",
  async (id) => {
    try {
      const response = await doPut(`/admin/user/${id}`);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      return toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  "changeUserPassword",
  async ({ data, id }) => {
    try {
      const response = await doPut(`/admin/user/changepassword/${id}`, data);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const getByIdUser = createAsyncThunk("getByIdUser", async (id) => {
  try {
    const response = await doGet(`/admin/user/${id}`);
    if (response.status === 200) {
      return response.data.data[0];
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const deleteUser = createAsyncThunk("deleteUser", async (id) => {
  try {
    const response = await doDelete(`/admin/user/${id}`);
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
