import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import Layout from "../../components/Layout";

import Modal from "../../components/Modal";
import {
  changeUserPassword,
  deleteUser,
  editUserBlockStatus,
  getByIdUser,
  getUser,
} from "../../redux/thunk/userThunk";
import { resetUser } from "../../redux/slice/userSlice";
import { url } from "../../utils/env";
import { userimg } from "../../constant/ImageList";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";

const ListUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user,
    userBlockStatus,
    userById,
    userDelete,
    userChangePassword,
    loading,
  } = useSelector((state) => state.userState);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [id, setId] = useState("");
  const [filter, setFilter] = useState({
    userType: null,
  });
  const [newPasswordType, setNewPasswordType] = useState(true);
  const [confirmPasswordType, setConfirmPasswordType] = useState(true);
  const [formFieldData, setFormFieldData] = useState({
    newpassword: "",
    cnewpassword: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [filteredUserData, setFilteredUserData] = useState([]);
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  useEffect(() => {
    if (userBlockStatus.success) {
      setShowBlockModal(false);
      navigate("/user");
      dispatch(resetUser());
    }
    dispatch(getUser());
  }, [userBlockStatus.success, dispatch, navigate]);
  useEffect(() => {
    if (userDelete.success) {
      setShowDeleteModal(false);

      dispatch(resetUser());
    }
    dispatch(getUser());
  }, [userDelete.success, dispatch, navigate]);

  useEffect(() => {
    if (userChangePassword.success) {
      setChangePasswordModal(false);
      setFormFieldData({
        newpassword: "",
        cnewpassword: "",
      });

      dispatch(resetUser());
    }
    dispatch(getUser());
  }, [userChangePassword, dispatch, navigate]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);
  useEffect(() => {
    if (showBlockModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showBlockModal]);
  useEffect(() => {
    if (changePasswordModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [changePasswordModal]);
  useEffect(() => {
    if (showDeleteModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showDeleteModal]);

  const handleChangePassword = async (event) => {
    event.preventDefault();

    if (!formFieldData?.newpassword) {
      toast.error("New Password is Required");
      return false;
    }
    if (!formFieldData?.cnewpassword) {
      toast.error("Confirm Password is Required");
      return false;
    }

    if (formFieldData?.newpassword !== formFieldData?.cnewpassword) {
      toast.error("New password and Confirm password do not match");
      return;
    }

    try {
      const data = {
        password: formFieldData.newpassword,
      };
      if (data) {
        const id = userById?.id;
        await new Promise((resolve) => setTimeout(resolve, 2000));
        dispatch(changeUserPassword({ data: data, id }));
      }
    } catch (err) {}
  };

  const [switchClicked, setSwitchClicked] = useState(false);
  const handleSwitchClick = (value) => {
    setId(value.id);
    setShowBlockModal(true);
  };

  useEffect(() => {
    const switchElement = document.querySelector(".switch");
    if (switchElement) {
      switchElement.classList.toggle("switchslide", switchClicked);
    }
  }, [switchClicked]);

  const filterUsers = (user, filter) => {
    if (!Array.isArray(user)) {
      return [];
    }
    return user?.filter((i) => {
      if (filter.userType === "Institute") {
        return i.user_type === "Institute";
      } else if (filter.userType === "Normal") {
        return i.user_type === "Normal";
      } else {
        return true;
      }
    });
  };

  useEffect(() => {
    setFilteredUserData(filterUsers(user, filter));
  }, [user, filter]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Full Name",
        accessor: (row) => row.full_name,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "User Type",
        accessor: "user_type",
      },
      {
        Header: "Verified",
        accessor: (row) => (
          <div className="statusdiv">
            <span
              style={{
                backgroundColor: getVerifiedBackgroundColor(row?.status),
              }}
              className="statusdiv-status"
            >
              {row?.status === 1 ? (
                <i class="fa-regular fa-check"></i>
              ) : (
                <i class="fa-regular fa-xmark"></i>
              )}
            </span>
          </div>
        ),
        headerStyle: {
          width: "100px",
        },
      },
      {
        Header: "Status",
        accessor: (row) => (
          <div className="statusdiv">
            <span
              style={{
                backgroundColor: getStatusBackgroundColor(row?.block_status),
              }}
              className="statusdiv-status"
            >
              {row?.block_status === 0 ? <>Active</> : <>Inactive</>}
            </span>
          </div>
        ),
        headerStyle: {
          width: "100px",
        },
      },
      {
        Header: "Block",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              className={`${
                row.block_status === 0 ? "toggle" : "toggleactive"
              }`}
              onClick={() => handleSwitchClick(row)}
            >
              <div
                className={` ${
                  row.block_status === 0 ? "switch" : "switchslide"
                }`}
              ></div>
            </div>
          </div>
        ),
      },

      {
        Header: "Received At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "170px",
        },
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              onClick={() => {
                dispatch(getByIdUser(row.id));
                setShowModal(true);
              }}
              className="actionlinks-view"
            >
              <i className="fa-regular fa-eye"></i>
            </div>
            {row.user_type === "Institute" && (
              <>
                <div
                  onClick={() => {
                    dispatch(getByIdUser(row.id));
                    setChangePasswordModal(true);
                  }}
                  className="actionlinks-password"
                >
                  <i className="fa-regular fa-key"></i>
                </div>
                <div
                  onClick={() => {
                    dispatch(getByIdUser(row.id));
                    setShowDeleteModal(true);
                  }}
                  className="actionlinks-delete"
                >
                  <i className="fa-regular fa-trash"></i>
                </div>
              </>
            )}
            {row.user_type === "Normal" && row.status !== 1 && (
              <>
                <div
                  onClick={() => {
                    dispatch(getByIdUser(row.id));
                    setShowDeleteModal(true);
                  }}
                  className="actionlinks-delete"
                >
                  <i className="fa-regular fa-trash"></i>
                </div>
              </>
            )}
          </div>
        ),
      },
    ],
    [dispatch]
  );

  const getStatusBackgroundColor = (block_status) => {
    if (block_status === 0) {
      return "green";
    } else if (block_status === 1) {
      return "#fe0000";
    }
  };
  const getVerifiedBackgroundColor = (status) => {
    if (status === 1) {
      return "green";
    } else if (status === 0) {
      return "#fe0000";
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => filteredUserData, [filteredUserData]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="listpage">
        <div className="listpage-top">
          <h1>Users</h1>
          <div className="listpage-top--textlink">
            <h2>
              <span>
                <i className="fa-regular fa-users"></i>
              </span>
              &nbsp;/ User Management
            </h2>
            <div className="listpage-top--textlink_addsetting">
              <Link className="secondary-button" to="/adduser">
                <span>
                  <i className="fa-regular fa-plus"></i>
                </span>
                &nbsp;&nbsp; Add User
              </Link>
            </div>
          </div>
        </div>

        <div className="listpage-searchentry">
          <div className="listpage-searchentry--search">
            <input
              type="search"
              placeholder="Search here..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setGlobalFilter(e.target.value);
              }}
            />
            <i className="fa-regular fa-magnifying-glass"></i>
          </div>
          <div className="listpage-searchentry--selects">
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <p>User Type: </p>
              <select
                className="listpage-searchentry--selects_filterselect"
                name="status"
                onChange={(e) =>
                  setFilter({ ...filter, userType: e.target.value })
                }
              >
                <option value="">All</option>
                <option value="Institute">Institute</option>
                <option value="Normal">Normal</option>
              </select>
            </div>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              className="listpage-searchentry--pageselect"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={user.length}>All</option>
            </select>
          </div>
        </div>

        <div className="listpage-tablesection">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} style={column.headerStyle}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="notfound">
                    Data not found.
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {pageSize !== user.length && (
            <div className="listpage-tablesection--pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          title={`Detail of ${userById?.full_name}`}
          handleCloseModal={() => setShowModal(false)}
        >
          <div className="userdetail" style={{ width: "500px" }}>
            <div className="userdetail-left">
              <p>
                <strong>Name: </strong> {userById?.full_name}
              </p>
              <p>
                <strong>Email: </strong>
                {userById?.email}
              </p>
              {userById?.phone && (
                <p>
                  <strong>Phone: </strong>
                  {userById?.phone}
                </p>
              )}
              {userById?.street_address && (
                <p>
                  <strong>Street Address: </strong>
                  {userById?.street_address}
                </p>
              )}
              {userById?.province && (
                <p>
                  <strong>State/Province: </strong>
                  {userById?.province}
                </p>
              )}
              {userById?.phone && (
                <p>
                  <strong>Phone: </strong>
                  {userById?.phone}
                </p>
              )}
              {userById?.city && (
                <p>
                  <strong>City: </strong>
                  {userById?.city}
                </p>
              )}
              {userById?.postal_code && (
                <p>
                  <strong>Zip/Postal Code: </strong>
                  {userById?.postal_code}
                </p>
              )}
              <p>
                <strong>Status: </strong>
                <span
                  style={{
                    backgroundColor: getStatusBackgroundColor(
                      userById?.block_status
                    ),
                  }}
                  className="statusdiv-status"
                >
                  {userById?.block_status === 0 ? <>Active</> : <>Inactive</>}
                </span>
              </p>
            </div>
            <div className="userdetail-right">
              <div className="userdetail-right--image">
                {userById?.image ? (
                  <img src={`${url}/user/${userById?.image}`} alt="" />
                ) : (
                  <img src={userimg} alt="" />
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showBlockModal && (
        <Modal
          title="Block User"
          handleCloseModal={() => setShowBlockModal(false)}
        >
          <div className="confirmdiv" style={{ width: "400px" }}>
            <i
              className="fa-regular fa-circle-exclamation"
              style={{ background: "none" }}
            ></i>
            <p>Are you sure you want to change status of this user?</p>
            <div className="confirmdiv-btns">
              <button
                className="primary-button"
                onClick={() => dispatch(editUserBlockStatus(id))}
              >
                Yes
              </button>
              <button
                className="secondary-button"
                onClick={() => setShowBlockModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Delete User "
          handleCloseModal={() => setShowDeleteModal(false)}
        >
          <div className="confirmdiv" style={{ width: "400px" }}>
            <i
              className="fa-regular fa-circle-exclamation"
              style={{ background: "none" }}
            ></i>
            <p>Are you sure you want to delete this user?</p>
            <div className="confirmdiv-btns">
              <button
                className="primary-button"
                onClick={() => dispatch(deleteUser(userById?.id))}
              >
                Yes
              </button>
              <button
                className="secondary-button"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}

      {changePasswordModal && (
        <Modal
          title="Change Password"
          handleCloseModal={() => setChangePasswordModal(false)}
        >
          <form
            action=""
            className="form"
            onSubmit={handleChangePassword}
            style={{ width: "400px" }}
          >
            <div>
              <label htmlFor="newpassword">New Password:</label>
              <div className="passwordbutton">
                <input
                  type={newPasswordType ? "password" : "text"}
                  name="newpassword"
                  id="newpassword"
                  placeholder="Enter new password ..."
                  value={formFieldData?.newpassword}
                  onChange={handleInputChange}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setNewPasswordType(!newPasswordType);
                  }}
                  className="passwordbutton-button"
                >
                  {newPasswordType ? (
                    <i className="fa-regular fa-eye-slash"></i>
                  ) : (
                    <i className="fa-regular fa-eye"></i>
                  )}
                </button>
              </div>
            </div>
            <div>
              <label htmlFor="cnewpassword">Confirm New Password:</label>
              <div className="passwordbutton">
                <input
                  type={confirmPasswordType ? "password" : "text"}
                  name="cnewpassword"
                  id="cnewpassword"
                  placeholder="Retype new password ..."
                  value={formFieldData?.cnewpassword}
                  onChange={handleInputChange}
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmPasswordType(!confirmPasswordType);
                  }}
                  className="passwordbutton-button"
                >
                  {confirmPasswordType ? (
                    <i className="fa-regular fa-eye-slash"></i>
                  ) : (
                    <i className="fa-regular fa-eye"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="submitbtn">
              <input
                type="submit"
                value="Change Password"
                className="secondary-button"
              />
            </div>
          </form>
        </Modal>
      )}
    </Layout>
  );
};

export default ListUser;
