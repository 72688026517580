import { createSlice } from "@reduxjs/toolkit";
import {
  addCourse,
  byIdCourse,
  deleteCourse,
  editCourse,
  editCourseDeal,
  getCourse,
  getCourseWithSeries,
} from "../thunk/courseThunk";

const initialState = {
  course: [],
  courseDelete: [],
  courseById: [],
  courseAdd: [],
  courseEdit: [],
  courseDealEdit: [],
  courseWithSeriesData: [],
  loading: false,
  success: false,
  error: null,
};

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    resetCourse: () => initialState,
    resetCourseById: (state) => {
      state.courseById = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.course = action.payload;
        state.error = null;
      })
      .addCase(getCourse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteCourse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.courseDelete = action.payload;
        state.error = null;
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addCourse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.courseAdd = action.payload;
        state.error = null;
      })
      .addCase(addCourse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editCourse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.courseEdit = action.payload;
        state.error = null;
      })
      .addCase(editCourse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(byIdCourse.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(byIdCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.courseById = action.payload;
        state.error = null;
      })
      .addCase(byIdCourse.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(getCourseWithSeries.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getCourseWithSeries.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.courseWithSeriesData = action.payload;
        state.error = null;
      })
      .addCase(getCourseWithSeries.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editCourseDeal.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editCourseDeal.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.courseDealEdit = action.payload;
        state.error = null;
      })
      .addCase(editCourseDeal.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetCourse, resetCourseById } = courseSlice.actions;
export default courseSlice.reducer;
