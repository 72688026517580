import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { blogAddAdmin } from "../../redux/thunk/blogThunk";
import { resetBlog } from "../../redux/slice/blogSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formats, modules } from "../../components/quill";
const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [formFieldData, setFormFieldData] = useState({
    title: "",
  });

  const { blogAdd, loading } = useSelector((state) => state.blogState);
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("title", formFieldData.title);
      formData.append("image", image);
      formData.append("description", description);

      if (formData) {
        dispatch(blogAddAdmin(formData));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (blogAdd.success) {
      navigate("/blog");
      dispatch(resetBlog());
    }
  }, [blogAdd.success, dispatch, navigate]);

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog">
        <h1 className="addeditblog-blogtext1">Add Blog</h1>
        <h1 className="addeditblog-blogtext2">Here you can add your Blog</h1>
        <form
          className="addeditblog-blogform"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="title" data-after=" *">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Enter blog title"
                value={formFieldData.title}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="image" data-after=" *">
                Thumbnail
              </label>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
              />
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{
                    width: "100%",
                    height: "250px",
                    margin: "10px 0px",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
          </div>

          <div className="addeditblog-blogform--right">
            <div>
              <label htmlFor="description" data-after=" *">
                Description
              </label>
              <ReactQuill
                style={{ paddingTop: "1rem" }}
                theme="snow"
                value={description}
                onChange={setDescription}
                modules={modules}
                formats={formats}
              />
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Add Blog"
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddBlog;
