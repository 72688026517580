import { createSlice } from "@reduxjs/toolkit";
import {
  deletePrivateSession,
  getByIdPrivateSession,
  getPrivateSession,
} from "../thunk/privateSessionThunk";

const initialState = {
  privateSession: [],
  privateSessionDelete: [],
  privateSessionById: [],
  loading: false,
  success: false,
  error: null,
};

const privateSessionSlice = createSlice({
  name: "privateSession",
  initialState,
  reducers: {
    resetPrivateSession: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrivateSession.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getPrivateSession.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.privateSession = action.payload;
        state.error = null;
      })
      .addCase(getPrivateSession.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deletePrivateSession.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deletePrivateSession.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.privateSessionDelete = action.payload;
        state.error = null;
      })
      .addCase(deletePrivateSession.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(getByIdPrivateSession.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getByIdPrivateSession.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.privateSessionById = action.payload;
        state.error = null;
      })
      .addCase(getByIdPrivateSession.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetPrivateSession } = privateSessionSlice.actions;
export default privateSessionSlice.reducer;
