import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { getCourse } from "../../redux/thunk/courseThunk";
import { addUser } from "../../redux/thunk/userThunk";
import { resetUser } from "../../redux/slice/userSlice";

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formFieldData, setFormFieldData] = useState({
    name: "",
    email: "",
    password: "",
    accessedCourse: "",
  });
  const { userAdd, loading } = useSelector((state) => state.userState);
  const { course, loading: courseLoading } = useSelector(
    (state) => state.courseState
  );
  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "radio" ? (checked ? value : "") : value;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };
  const generatePassword = () => {
    const newPassword = generateRandomPassword();
    setFormFieldData({ ...formFieldData, password: newPassword });
  };

  const generateRandomPassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let newPassword = "";
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newPassword += characters.charAt(randomIndex);
    }
    return newPassword;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        full_name: formFieldData.name,
        email: formFieldData.email,
        password: formFieldData.password,
        access_course: formFieldData.accessedCourse,
      };

      if (data) {
        dispatch(addUser(data));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (userAdd?.success) {
      navigate(`/user`);
      dispatch(resetUser());
    }
  }, [userAdd?.success, navigate, dispatch]);

  return (
    <Layout>
      {(loading || courseLoading) && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Add User</h1>
        <h1 className="addeditblog-blogtext2">
          Here you can add institute user
        </h1>
        <form className="addeditblog-blogform" onSubmit={handleSubmit}>
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="name" data-after=" *">
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="eg. Anil Shrestha"
                value={formFieldData.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="email" data-after=" *">
                Email
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="eg. freeieltspte.ram1234@gmail.com"
                value={formFieldData.email}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="password" data-after=" *">
                Password
              </label>
              <div className="passwordbutton">
                <input
                  type="text"
                  name="password"
                  id="password"
                  placeholder="eg. **********"
                  value={formFieldData?.password}
                  onChange={handleInputChange}
                />

                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    generatePassword();
                  }}
                  className="passwordbutton-button"
                  style={{
                    background: "#ff4500",
                    color: "white",
                    padding: "8px 24px",
                    top: "30%",
                    right: "1%",
                    borderRadius: "8px",
                  }}
                >
                  Generate Password
                </button>
              </div>
            </div>

            <div>
              <label htmlFor="description" data-after=" *">
                Give Access To Course
              </label>
              <div
                style={{ display: "flex", gap: "1rem", paddingTop: "0.5rem" }}
              >
                {course.map((value, i) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <input
                      type="radio"
                      name="accessedCourse"
                      id={value.slug}
                      value={value.slug}
                      checked={formFieldData?.accessedCourse === value.slug}
                      onChange={handleInputChange}
                    />
                    <label htmlFor={value.slug}>{value.name}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Add User"
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddUser;
