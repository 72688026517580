import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import {
  authorByIdAdmin,
  authorEditAdmin,
} from "../../redux/thunk/authorThunk";
import { resetAuthor } from "../../redux/slice/authorSlice";
import { url } from "../../utils/env";
const EditAuthor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [formFieldData, setFormFieldData] = useState({
    name: "",
  });
  const { authorEdit, authorById, loading } = useSelector(
    (state) => state.authorState
  );
  useEffect(() => {
    dispatch(authorByIdAdmin(id));
    return () => {
      dispatch(resetAuthor());
    };
  }, [id, dispatch]);
  useEffect(() => {
    if (authorEdit.success) {
      navigate("/author", { replace: true });
      dispatch(resetAuthor());
    }
  }, [authorEdit.success, dispatch, navigate]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("name", formFieldData.name);
      formData.append("image", image);

      if (formData) {
        dispatch(authorEditAdmin({ data: formData, id }));
      }
    } catch (err) {}
  };

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Edit Author</h1>
        <h1 className="addeditblog-blogtext2">Here you can edit author</h1>
        <form
          className="addeditblog-blogform"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="name" data-after=" *">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter author name"
                defaultValue={authorById?.name}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="image" data-after=" *">
                Image
              </label>
              <input
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
              />
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{
                    width: "300px",
                    height: "200px",
                    margin: "10px 0px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={`${url}/author/${authorById.image}`}
                  alt="Selected"
                  style={{
                    width: "300px",
                    height: "200px",
                    margin: "10px 0px",
                    objectFit: "contain",
                  }}
                />
              )}
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Edit Author"
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditAuthor;
