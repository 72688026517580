import { createAsyncThunk } from "@reduxjs/toolkit";
import { doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const changePassword = createAsyncThunk(
  "changePassword",
  async (data) => {
    try {
      const response = await doPut(`/admin/change-password`, data);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        return response.data;
      }
      throw new Error(`Password change failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message);
      throw error;
    }
  }
);
