import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import Layout from "../../components/Layout";

import Modal from "../../components/Modal";
import {
  getByIdSubscription,
  getSubscription,
} from "../../redux/thunk/subscriptionThunk";
import Loader from "../../components/loader/Loader";

const ListSubscription = () => {
  const dispatch = useDispatch();

  const { subscriptionData, subscriptionByIdData, loading } = useSelector(
    (state) => state.subscriptionState
  );
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    dispatch(getSubscription());
  }, [dispatch]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Order Id",
        accessor: (row) => row.order_id,
      },
      // {
      //   Header: "Membership",
      //   accessor: (row) =>
      //     row.membership_name ? (
      //       row.membership_name
      //     ) : (
      //       <div style={{ textAlign: "center" }}>N/A</div>
      //     ),
      // },
      {
        Header: "Course",
        accessor: (row) =>
          row.course_name ? (
            row.course_name
          ) : (
            <div style={{ textAlign: "center" }}>N/A</div>
          ),
      },
      {
        Header: "Series",
        accessor: (row) =>
          row.series_name ? (
            row.series_name
          ) : (
            <div style={{ textAlign: "center" }}>N/A</div>
          ),
      },
      {
        Header: "Payment Status",
        accessor: (row) => (
          <div className="statusdiv">
            <span
              style={{
                backgroundColor: getStatusBackgroundColor(row?.payment_status),
              }}
              className="statusdiv-status"
            >
              {row?.payment_status}
            </span>
          </div>
        ),
        headerStyle: {
          width: "150px",
        },
      },
      {
        Header: "Payment Method",
        accessor: (row) =>
          row.payment_method ? (
            row.payment_method
          ) : (
            <div style={{ textAlign: "center" }}>N/A</div>
          ),
      },

      {
        Header: "Subscribed At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              onClick={() => {
                dispatch(getByIdSubscription(row?.id));
                setShowModal(true);
              }}
              className="actionlinks-view"
            >
              <i className="fa-regular fa-eye"></i>
            </div>
          </div>
        ),
      },
    ],
    [dispatch]
  );
  const getStatusBackgroundColor = (payment_status) => {
    if (payment_status === "Paid") {
      return "green";
    } else {
      return "#fe0000";
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => subscriptionData || [], [subscriptionData]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="listpage">
        <div className="listpage-top">
          <h1>Subscriptions</h1>
          <div className="listpage-top--textlink">
            <h2>
              <span>
                <i className="fa-regular fa-cart-circle-check"></i>
              </span>
              &nbsp;/ Subscription Management
            </h2>
            <div className="listpage-top--textlink_addsetting"></div>
          </div>
        </div>

        <div className="listpage-searchentry">
          <div className="listpage-searchentry--search">
            <input
              type="search"
              placeholder="Search here..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setGlobalFilter(e.target.value);
              }}
            />
            <i className="fa-regular fa-magnifying-glass"></i>
          </div>

          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="listpage-searchentry--pageselect"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={subscriptionData?.length}>All</option>
          </select>
        </div>

        <div className="listpage-tablesection">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} style={column.headerStyle}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="notfound">
                    Data not found.
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {pageSize !== subscriptionData?.length && (
            <div className="listpage-tablesection--pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          title={`Subscription Detail`}
          handleCloseModal={() => setShowModal(false)}
        >
          <div className="modalcontent" style={{ width: "500px" }}>
            <p>
              <strong>Order Id: </strong> {subscriptionByIdData?.order_id}
            </p>
            <p>
              <strong>User Id: </strong> {subscriptionByIdData?.user_id}
            </p>
            <p>
              <strong>Username: </strong> {subscriptionByIdData?.username}
            </p>
            {/* {subscriptionByIdData?.membership_name && (
              <p>
                <strong>Membership Name: </strong>
                {subscriptionByIdData?.membership_name}
              </p>
            )} */}
            {subscriptionByIdData?.course_name && (
              <p>
                <strong>Course Name: </strong>
                {subscriptionByIdData?.course_name}
              </p>
            )}

            {subscriptionByIdData?.series_name && (
              <p>
                <strong>Course Name: </strong>
                {subscriptionByIdData?.series_name}
              </p>
            )}
            <p>
              <strong>Amount: </strong>
              {subscriptionByIdData?.amount}
            </p>
            <p>
              <strong>Duration: </strong>
              {subscriptionByIdData?.duration} Month
            </p>
            <p>
              <strong>Subscribed At: </strong>
              {moment(subscriptionByIdData?.created_at).format("MMM Do YYYY")}
            </p>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default ListSubscription;
