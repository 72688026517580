import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { addSeries } from "../../redux/thunk/seriesThunk";
import { resetSeries } from "../../redux/slice/seriesSlice";
import { getCourse } from "../../redux/thunk/courseThunk";

const AddSeries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [selectedCourse, setSelectedCourse] = useState("");
  const [formFieldData, setFormFieldData] = useState({
    name: "",
    price: "",
    duration: "",
    description: "",
  });
  const { seriesAdd, loading } = useSelector((state) => state.seriesState);
  const { course } = useSelector((state) => state.courseState);
  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);
  useEffect(() => {
    if (slug) {
      setSelectedCourse(slug);
    }
  }, [slug]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        course_slug: selectedCourse,
        name: formFieldData.name,
        price: formFieldData.price,
        duration: formFieldData.duration,
        description: formFieldData.description,
      };

      if (data) {
        dispatch(addSeries(data));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (seriesAdd.success) {
      navigate(`/${slug}/series`);
      dispatch(resetSeries());
    }
  }, [seriesAdd.success, navigate, dispatch, slug]);

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Add Series </h1>
        <h1 className="addeditblog-blogtext2">Here you can add your series</h1>
        <form className="addeditblog-blogform" onSubmit={handleSubmit}>
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="course_slug" data-after=" *">
                Course Name
              </label>
              <select
                name="course_slug"
                id="course_slug"
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
                className="customselect"
              >
                <option value="">-- Select Course Name --</option>
                {course?.map((value, i) => (
                  <option value={value.slug} key={i}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="name" data-after=" *">
                Series Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="eg. Listening"
                value={formFieldData.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="price" data-after=" *">
                Series Price
              </label>
              <input
                type="text"
                id="price"
                name="price"
                placeholder="eg. Rs 1000"
                value={formFieldData.price}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="duration" data-after=" *">
                Series Duration (/mo)
              </label>
              <input
                type="text"
                id="duration"
                name="duration"
                placeholder="eg. 2"
                value={formFieldData.duration}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="description" data-after=" *">
                Series Description
              </label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="4"
                placeholder="Series description here ..."
                value={formFieldData.description}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Add Series "
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddSeries;
