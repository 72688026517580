import { createSlice } from "@reduxjs/toolkit";
import {
  addVideo,
  addVideoImage,
  addVideoPdf,
  deleteVideo,
  deleteVideoImage,
  deleteVideoPdf,
  editVideo,
  getByIdVideo,
  getVideo,
} from "../thunk/videoThunk";

const initialState = {
  video: [],
  videoDelete: [],
  videoById: [],
  videoAdd: [],
  videoEdit: [],
  videoImageAdd: [],
  videoImageDelete: [],
  videoPdfAdd: [],
  videoPdfDelete: [],
  loading: false,
  success: false,
  error: null,
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    resetVideo: () => initialState,
    resetVideoById: (state) => {
      state.videoById = [];
    },
    resetVideoImage: (state) => {
      state.videoImageAdd = [];
      state.videoImageDelete = [];
    },
    resetVideoPdf: (state) => {
      state.videoPdfAdd = [];
      state.videoPdfDelete = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideo.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.video = action.payload;
        state.error = null;
      })
      .addCase(getVideo.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteVideo.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.videoDelete = action.payload;
        state.error = null;
      })
      .addCase(deleteVideo.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(getByIdVideo.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getByIdVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.videoById = action.payload;
        state.error = null;
      })
      .addCase(getByIdVideo.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addVideo.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.videoAdd = action.payload;
        state.error = null;
      })
      .addCase(addVideo.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editVideo.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.videoEdit = action.payload;
        state.error = null;
      })
      .addCase(editVideo.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addVideoImage.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addVideoImage.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.videoImageAdd = action.payload;
        state.error = null;
      })
      .addCase(addVideoImage.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addVideoPdf.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addVideoPdf.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.videoPdfAdd = action.payload;
        state.error = null;
      })
      .addCase(addVideoPdf.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteVideoImage.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteVideoImage.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.videoImageDelete = action.payload;
        state.error = null;
      })
      .addCase(deleteVideoImage.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteVideoPdf.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteVideoPdf.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.videoPdfDelete = action.payload;
        state.error = null;
      })
      .addCase(deleteVideoPdf.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetVideo, resetVideoById, resetVideoImage, resetVideoPdf } =
  videoSlice.actions;
export default videoSlice.reducer;
