import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/main.min.css";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectiveRoute from "./components/ProtectiveRoute";
import Dashboard from "./pages/dashboard/Dashboard";
import ListQuery from "./pages/query/ListQuery";
import ListBlog from "./pages/blog/ListBlog";
import AddBlog from "./pages/blog/AddBlog";
import EditBlog from "./pages/blog/EditBlog";
import ListCourse from "./pages/course/ListCourse";
import AddCourse from "./pages/course/AddCourse";
import EditCourse from "./pages/course/EditCourse";
import ListSeries from "./pages/series/ListSeries";
import AddSeries from "./pages/series/AddSeries";
import EditSeries from "./pages/series/EditSeries";
import ListVideo from "./pages/video/ListVideo";
import ListSection from "./pages/section/ListSection";
import AddSection from "./pages/section/AddSection";
import EditSection from "./pages/section/EditSection";
import AddVideo from "./pages/video/AddVideo";
import EditVideo from "./pages/video/EditVideo";
import ListPrivateSession from "./pages/private-session/ListPrivateSession.js";
import ListUser from "./pages/user/ListUser.js";
import ListNotification from "./pages/notification/ListNotification.js";
import AddNotification from "./pages/notification/AddNotification.js";
import EditNotification from "./pages/notification/EditNotification.js";
import ListSubscription from "./pages/subscription/ListSubscription.js";
import ListMembership from "./pages/membership/ListMembership.js";
import AddMembership from "./pages/membership/AddMembership.js";
import EditMembership from "./pages/membership/EditMembership.js";
import AddUser from "./pages/user/AddUser.js";
import ListAuthor from "./pages/author/ListAuthor.js";
import AddAuthor from "./pages/author/AddAuthor.js";
import EditAuthor from "./pages/author/EditAuthor.js";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer autoClose={3000} />
        <Routes>
          <Route path="/" element={<Login />} />

          <Route
            path="/dashboard"
            element={
              <ProtectiveRoute redirectTo="/">
                <Dashboard />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/query"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListQuery />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/user"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListUser />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/adduser"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddUser />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/private-session"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListPrivateSession />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/blog"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListBlog />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/addblog"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddBlog />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/editblog/:slug"
            element={
              <ProtectiveRoute redirectTo="/">
                <EditBlog />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/author"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListAuthor />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/addauthor"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddAuthor />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/editauthor/:id"
            element={
              <ProtectiveRoute redirectTo="/">
                <EditAuthor />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/notification"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListNotification />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/addnotification"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddNotification />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/editnotification/:id"
            element={
              <ProtectiveRoute redirectTo="/">
                <EditNotification />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/subscription"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListSubscription />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/course"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListCourse />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/addcourse"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddCourse />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/editcourse/:slug"
            element={
              <ProtectiveRoute redirectTo="/">
                <EditCourse />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/:slug/series"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListSeries />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/:slug/addseries"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddSeries />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/:course_slug/editseries/:slug"
            element={
              <ProtectiveRoute redirectTo="/">
                <EditSeries />
              </ProtectiveRoute>
            }
          />

          <Route
            path="/membership"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListMembership />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/addmembership"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddMembership />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/editmembership/:slug"
            element={
              <ProtectiveRoute redirectTo="/">
                <EditMembership />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/:course_slug/:series_slug"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListVideo />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/:course_slug/:series_slug/addvideo"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddVideo />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/:course_slug/:series_slug/editvideo/:slug"
            element={
              <ProtectiveRoute redirectTo="/">
                <EditVideo />
              </ProtectiveRoute>
            }
          />

          <Route
            path="/:course_slug/:series_slug/section"
            element={
              <ProtectiveRoute redirectTo="/">
                <ListSection />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/:course_slug/:series_slug/addsection"
            element={
              <ProtectiveRoute redirectTo="/">
                <AddSection />
              </ProtectiveRoute>
            }
          />
          <Route
            path="/:course_slug/:series_slug/editsection/:slug"
            element={
              <ProtectiveRoute redirectTo="/">
                <EditSection />
              </ProtectiveRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
