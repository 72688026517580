import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { byIdSection, editSection } from "../../redux/thunk/sectionThunk";
import { resetSection } from "../../redux/slice/sectionSlice";
import { getCourse } from "../../redux/thunk/courseThunk";
import { getSeries } from "../../redux/thunk/seriesThunk";

const EditSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { slug, course_slug, series_slug } = useParams();

  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedSeries, setSelectedSeries] = useState("");
  const [formFieldData, setFormFieldData] = useState({
    name: "",
  });

  const { sectionEdit, sectionById, loading } = useSelector(
    (state) => state.sectionState
  );
  const { course } = useSelector((state) => state.courseState);
  const { series } = useSelector((state) => state.seriesState);
  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getSeries());
  }, [dispatch]);
  useEffect(() => {
    dispatch(byIdSection(slug));

    return () => {
      dispatch(resetSection());
    };
  }, [slug, dispatch]);
  useEffect(() => {
    if (course_slug) {
      setSelectedCourse(course_slug);
    }
  }, [course_slug]);
  useEffect(() => {
    if (series_slug) {
      setSelectedSeries(series_slug);
    }
  }, [series_slug]);

  useEffect(() => {
    if (sectionEdit.success) {
      navigate(`/${course_slug}/${series_slug}/section`);
      dispatch(resetSection());
    }
  }, [sectionEdit.success, dispatch, navigate, course_slug, series_slug]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        course_slug: selectedCourse,
        series_slug: selectedSeries,
        name: formFieldData.name,
      };

      if (data) {
        dispatch(editSection({ data: data, slug }));
      }
    } catch (err) {}
  };

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Edit Section </h1>
        <h1 className="addeditblog-blogtext2">
          Here you can Edit your section
        </h1>
        <form className="addeditblog-blogform" onSubmit={handleSubmit}>
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="course_slug" data-after=" *">
                Course Name
              </label>
              <select
                name="course_slug"
                id="course_slug"
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
                className="customselect"
              >
                <option value="">-- Select Course Name --</option>
                {course?.map((value, i) => (
                  <option value={value.slug} key={i}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="series_slug" data-after=" *">
                Series Name
              </label>
              <select
                name="series_slug"
                id="series_slug"
                value={selectedSeries}
                onChange={(e) => setSelectedSeries(e.target.value)}
                className="customselect"
              >
                <option value="">-- Select Series Name --</option>
                {series
                  ?.filter((s) => s.course_slug === selectedCourse)
                  .map((value, i) => (
                    <option value={value.slug} key={i}>
                      {value.name}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label htmlFor="name" data-after=" *">
                Section Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="eg. Introduction."
                defaultValue={sectionById?.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Edit Section "
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditSection;
