import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { getDashboard } from "../../redux/thunk/dashboardThunk";
import Loader from "../../components/loader/Loader";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardData, loading } = useSelector(
    (state) => state.dashboardState
  );
  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="dashboard">
        <div className="dashboard-top">
          <h1>Dashboard</h1>
          <h2>
            <span>
              <i className="fa-regular fa-house"></i>
            </span>
            &nbsp;/ Dashboard
          </h2>
        </div>

        <div className="dashboard-analytics">
          <Link to="/user" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-user"></i>
            </div>
            <h2>Users</h2>
            <h1>{dashboardData.userCount ?? 0}</h1>
          </Link>

          <Link to="/course" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-language"></i>
            </div>
            <h2>Course</h2>
            <h1>{dashboardData.courseCount ?? 0}</h1>
          </Link>

          <Link to="#" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-language"></i>
            </div>
            <h2>Series</h2>
            <h1>{dashboardData.seriesCount ?? 0}</h1>
          </Link>

          <Link to="/blog" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-blog"></i>
            </div>
            <h2>Blogs</h2>
            <h1>{dashboardData.blogCount ?? 0}</h1>
          </Link>

          <Link to="/private-session" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-screen-users"></i>
            </div>
            <h2>Private Session</h2>
            <h1>{dashboardData.privateSessionCount ?? 0}</h1>
          </Link>

          <Link to="/query" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-circle-question"></i>
            </div>
            <h2>Queries</h2>
            <h1>{dashboardData.contactusCount ?? 0}</h1>
          </Link>

          <Link to="/notification" className="dashboard-analytics--card">
            <div className="dashboard-analytics--card_icon">
              <i className="fa-regular fa-bell"></i>
            </div>
            <h2>Notifications</h2>
            <h1>{dashboardData.notification ?? 0}</h1>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
