import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCourseClick: false,
};

const courseClickSlice = createSlice({
  name: "courseClick",
  initialState,
  reducers: {
    toggleCourseClick: (state) => {
      // state.isCourseClick = !state.isCourseClick;
      state.isCourseClick = true;
    },
  },
});

export const { toggleCourseClick } = courseClickSlice.actions;
export default courseClickSlice.reducer;
