import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";
import Layout from "../../components/Layout";
import {
  queryAdmin,
  queryByIdAdmin,
  queryDeleteAdmin,
} from "../../redux/thunk/queryThunk";
import { resetQuery } from "../../redux/slice/querySlice";
import Modal from "../../components/Modal";
import Loader from "../../components/loader/Loader";

const ListQuery = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { query, queryDelete, queryById, loading } = useSelector(
    (state) => state.queryState
  );
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    dispatch(queryAdmin());
  }, [dispatch]);
  useEffect(() => {
    if (queryDelete.success) {
      setShowDeleteModal(false);
      navigate("/query");
      dispatch(resetQuery());
    }
    dispatch(queryAdmin());
  }, [queryDelete.success, navigate, dispatch]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);
  useEffect(() => {
    if (showDeleteModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showDeleteModal]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Full Name",
        accessor: (row) => row.full_name,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Message",
        accessor: (row) =>
          row.message?.split(" ")?.slice(0, 15)?.join(" ") + "...",
        headerStyle: {
          width: "300px",
        },
      },
      {
        Header: "Received At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              onClick={() => {
                dispatch(queryByIdAdmin(row.id));
                setShowModal(true);
              }}
              className="actionlinks-view"
            >
              <i className="fa-regular fa-eye"></i>
            </div>
            <div
              onClick={() => {
                dispatch(queryByIdAdmin(row.id));
                setShowDeleteModal(true);
              }}
              className="actionlinks-delete"
            >
              <i className="fa-regular fa-trash"></i>
            </div>
          </div>
        ),
      },
    ],
    [dispatch]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => query, [query]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="listpage">
        <div className="listpage-top">
          <h1>Queries</h1>
          <div className="listpage-top--textlink">
            <h2>
              <span>
                <i className="fa-regular fa-circle-question"></i>
              </span>
              &nbsp;/ Query Management
            </h2>
            <div className="listpage-top--textlink_addsetting"></div>
          </div>
        </div>

        <div className="listpage-searchentry">
          <div className="listpage-searchentry--search">
            <input
              type="search"
              placeholder="Search here..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setGlobalFilter(e.target.value);
              }}
            />
            <i className="fa-regular fa-magnifying-glass"></i>
          </div>

          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="listpage-searchentry--pageselect"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={query.length}>All</option>
          </select>
        </div>

        <div className="listpage-tablesection">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} style={column.headerStyle}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="notfound">
                    Data not found.
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {pageSize !== query.length && (
            <div className="listpage-tablesection--pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          title={`Query by ${queryById?.full_name}`}
          handleCloseModal={() => setShowModal(false)}
        >
          <div className="modalcontent" style={{ width: "500px" }}>
            <p>
              <strong>Name: </strong> {queryById?.full_name}
            </p>
            <p>
              <strong>Email: </strong>
              {queryById?.email}
            </p>
            <p>
              <strong>Message:</strong>
            </p>
            <p>
              <strong>➣ </strong>
              {queryById?.message}
            </p>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Delete Query"
          handleCloseModal={() => setShowDeleteModal(false)}
        >
          <div className="confirmdiv" style={{ width: "400px" }}>
            <i
              className="fa-regular fa-circle-exclamation"
              style={{ background: "none" }}
            ></i>
            <p>Are you sure you want to delete this query?</p>
            <div className="confirmdiv-btns">
              <button
                className="primary-button"
                onClick={() => dispatch(queryDeleteAdmin(queryById?.id))}
              >
                Yes
              </button>
              <button
                className="secondary-button"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default ListQuery;
