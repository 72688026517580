import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slice/loginSlice";
import sidebarReducer from "./slice/sidebarSlice";
import navlinkReducer from "./slice/navlinkSlice";
import queryReducer from "./slice/querySlice";
import blogReducer from "./slice/blogSlice";
import authorReducer from "./slice/authorSlice";
import courseReducer from "./slice/courseSlice";
import seriesReducer from "./slice/seriesSlice";
import sectionReducer from "./slice/sectionSlice";
import videoReducer from "./slice/videoSlice";
import userReducer from "./slice/userSlice";
import changePasswordReducer from "./slice/changePasswordSlice";
import notificationReducer from "./slice/notificationSlice";
import privateSessionReducer from "./slice/privateSessionSlice";
import dashboardReducer from "./slice/dashboardSlice";
import subscriptionReducer from "./slice/subscriptionSlice";
import courseClickReducer from "./slice/courseClickSlice";
import membershipReducer from "./slice/membershipSlice";
const store = configureStore({
  reducer: {
    loginState: loginReducer,
    sidebarState: sidebarReducer,
    navlinkState: navlinkReducer,
    courseClickState: courseClickReducer,
    queryState: queryReducer,
    blogState: blogReducer,
    authorState: authorReducer,
    courseState: courseReducer,
    seriesState: seriesReducer,
    sectionState: sectionReducer,
    videoState: videoReducer,
    userState: userReducer,
    changePasswordState: changePasswordReducer,
    notificationState: notificationReducer,
    privateSessionState: privateSessionReducer,
    dashboardState: dashboardReducer,
    subscriptionState: subscriptionReducer,
    membershipState: membershipReducer,
  },
});

export default store;
