import { createSlice } from "@reduxjs/toolkit";
import {
  addSeries,
  byIdSeries,
  deleteSeries,
  editSeries,
  editSeriesDeal,
  getSeries,
} from "../thunk/seriesThunk";

const initialState = {
  series: [],
  seriesDelete: [],
  seriesById: [],
  seriesAdd: [],
  seriesEdit: [],
  seriesDealEdit: [],
  loading: false,
  success: false,
  error: null,
};

const seriesSlice = createSlice({
  name: "series",
  initialState,
  reducers: {
    resetSeries: () => initialState,
    resetSeriesById: (state) => {
      state.seriesById = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeries.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getSeries.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.series = action.payload;
        state.error = null;
      })
      .addCase(getSeries.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteSeries.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteSeries.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.seriesDelete = action.payload;
        state.error = null;
      })
      .addCase(deleteSeries.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addSeries.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addSeries.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.seriesAdd = action.payload;
        state.error = null;
      })
      .addCase(addSeries.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editSeries.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editSeries.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.seriesEdit = action.payload;
        state.error = null;
      })
      .addCase(editSeries.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(byIdSeries.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(byIdSeries.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.seriesById = action.payload;
        state.error = null;
      })
      .addCase(byIdSeries.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editSeriesDeal.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editSeriesDeal.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.seriesDealEdit = action.payload;
        state.error = null;
      })
      .addCase(editSeriesDeal.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetSeries, resetSeriesById } = seriesSlice.actions;
export default seriesSlice.reducer;
