import React from "react";

import { Navigate } from "react-router-dom";

const ProtectiveRoute = ({ children, redirectTo }) => {
  const isAuthenticated = localStorage.getItem("siliconToken");
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export default ProtectiveRoute;
