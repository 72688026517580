import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { addMembership } from "../../redux/thunk/membershipThunk";
import { resetMembership } from "../../redux/slice/membershipSlice";

const AddMembership = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formFieldData, setFormFieldData] = useState({
    name: "",
    price: "",
    duration: "",
    description: "",
  });
  const { membershipAdd, loading } = useSelector(
    (state) => state.membershipState
  );
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        duration: formFieldData?.duration,
        name: formFieldData?.name,
        price: formFieldData?.price,
        description: formFieldData?.description,
      };

      if (data) {
        dispatch(addMembership(data));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (membershipAdd.success) {
      navigate(`/membership`);
      dispatch(resetMembership());
    }
  }, [membershipAdd.success, dispatch, navigate]);

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Add Membership </h1>
        <h1 className="addeditblog-blogtext2">
          Here you can add your membership
        </h1>
        <form className="addeditblog-blogform" onSubmit={handleSubmit}>
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="name" data-after=" *">
                Membership Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="eg. 2 Months Plan"
                value={formFieldData.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="price" data-after=" *">
                Membership Price
              </label>
              <input
                type="text"
                id="price"
                name="price"
                placeholder="eg. Rs 8000"
                value={formFieldData.price}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="duration" data-after=" *">
                Membership Duration
              </label>
              <input
                type="text"
                id="duration"
                name="duration"
                placeholder="eg. 2"
                value={formFieldData.duration}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="description" data-after=" *">
                Membership Description
              </label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="4"
                placeholder="Membership description here ..."
                value={formFieldData.description}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Add Membership "
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddMembership;
