import { createSlice } from "@reduxjs/toolkit";
import {
  queryAdmin,
  queryByIdAdmin,
  queryDeleteAdmin,
} from "../thunk/queryThunk";

const initialState = {
  query: [],
  queryDelete: [],
  queryById: [],
  loading: false,
  success: false,
  error: null,
};

const querySlice = createSlice({
  name: "query",
  initialState,
  reducers: {
    resetQuery: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(queryAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(queryAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.query = action.payload;
        state.error = null;
      })
      .addCase(queryAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(queryDeleteAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(queryDeleteAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.queryDelete = action.payload;
        state.error = null;
      })
      .addCase(queryDeleteAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(queryByIdAdmin.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(queryByIdAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.queryById = action.payload;
        state.error = null;
      })
      .addCase(queryByIdAdmin.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetQuery } = querySlice.actions;
export default querySlice.reducer;
