import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { addNotification } from "../../redux/thunk/notificationThunk";
import { resetNotification } from "../../redux/slice/notificationSlice";

const AddNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formFieldData, setFormFieldData] = useState({
    title: "",
    message: "",
  });
  const { notificationAdd, loading } = useSelector(
    (state) => state.notificationState
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        title: formFieldData.title,
        message: formFieldData.message,
      };

      if (data) {
        dispatch(addNotification(data));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (notificationAdd.success) {
      navigate("/notification");
      dispatch(resetNotification());
    }
  }, [notificationAdd.success, dispatch, navigate]);
  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Add Notification </h1>
        <h1 className="addeditblog-blogtext2">
          Here you can add your notification
        </h1>
        <form className="addeditblog-blogform" onSubmit={handleSubmit}>
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="title" data-after=" *">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="eg. Latest Offer"
                value={formFieldData.title}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="message" data-after=" *">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="4"
                placeholder="Notification message here ..."
                value={formFieldData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Add Notification "
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default AddNotification;
