import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPost, doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const getCourse = createAsyncThunk("getCourse", async () => {
  try {
    const response = await doGet("/admin/course");
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const addCourse = createAsyncThunk("addCourse", async (data) => {
  try {
    const response = await doPost(`/admin/course`, data);
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const editCourse = createAsyncThunk(
  "editCourse",
  async ({ data, slug }) => {
    try {
      const response = await doPut(`/admin/course/${slug}`, data);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const editCourseDeal = createAsyncThunk(
  "editCourseDeal",
  async ({ data, slug }) => {
    try {
      const response = await doPut(`/admin/course/deal/${slug}`, data);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const deleteCourse = createAsyncThunk("deleteCourse", async (slug) => {
  try {
    const response = await doDelete(`/admin/course/${slug}`);
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
export const byIdCourse = createAsyncThunk("byIdCourse", async (slug) => {
  try {
    const response = await doGet(`/admin/course/${slug}`);
    if (response.status === 200) {
      return response.data.data[0];
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const getCourseWithSeries = createAsyncThunk(
  "getCourseWithSeries",
  async (slug) => {
    try {
      const response = await doGet(`/admin/course-with-series`);
      if (response.status === 200) {
        return response.data.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);
