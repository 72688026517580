import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { byIdSeries, editSeries } from "../../redux/thunk/seriesThunk";
import { resetSeries } from "../../redux/slice/seriesSlice";
import { getCourse } from "../../redux/thunk/courseThunk";

const EditSeries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug, course_slug } = useParams();

  const [selectedCourse, setSelectedCourse] = useState("");
  const [formFieldData, setFormFieldData] = useState({
    name: "",
    price: "",
    duration: "",
    description: "",
  });

  const { seriesEdit, seriesById, loading } = useSelector(
    (state) => state.seriesState
  );
  const { course } = useSelector((state) => state.courseState);
  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);
  useEffect(() => {
    dispatch(byIdSeries(slug));

    return () => {
      dispatch(resetSeries());
    };
  }, [slug, dispatch]);
  useEffect(() => {
    if (course_slug) {
      setSelectedCourse(course_slug);
    }
  }, [course_slug]);

  useEffect(() => {
    if (seriesEdit.success) {
      navigate(`/${course_slug}/series`);
      dispatch(resetSeries());
    }
  }, [seriesEdit.success, navigate, dispatch, course_slug]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        course_slug: selectedCourse,
        name: formFieldData.name,
        price: formFieldData.price,
        duration: formFieldData.duration,
        description: formFieldData.description,
      };

      if (data) {
        dispatch(editSeries({ data: data, slug }));
      }
    } catch (err) {}
  };

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Edit Series </h1>
        <h1 className="addeditblog-blogtext2">Here you can Edit your series</h1>
        <form className="addeditblog-blogform" onSubmit={handleSubmit}>
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="course_slug" data-after=" *">
                Course Name
              </label>
              <select
                name="course_slug"
                id="course_slug"
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
                className="customselect"
              >
                <option value="">-- Select Course Name --</option>
                {course?.map((value, i) => (
                  <option value={value.slug} key={i}>
                    {value.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="name" data-after=" *">
                Series Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter series section ..."
                defaultValue={seriesById?.name}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="price" data-after=" *">
                Series Price
              </label>
              <input
                type="text"
                id="price"
                name="price"
                placeholder="eg. Rs 4000"
                defaultValue={seriesById.price}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="duration" data-after=" *">
                Series Duration (/mo)
              </label>
              <input
                type="text"
                id="duration"
                name="duration"
                placeholder="eg. 2"
                defaultValue={seriesById.duration}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="description" data-after=" *">
                Series Description
              </label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="4"
                placeholder="Series description here ..."
                defaultValue={seriesById.description}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Edit Series "
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditSeries;
