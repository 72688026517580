import { createSlice } from "@reduxjs/toolkit";
import {
  addMembership,
  byIdMembership,
  deleteMembership,
  editMembership,
  editMembershipDeal,
  getMembership,
} from "../thunk/membershipThunk";

const initialState = {
  membership: [],
  membershipDelete: [],
  membershipById: [],
  membershipAdd: [],
  membershipEdit: [],
  membershipDealEdit: [],
  loading: false,
  success: false,
  error: null,
};

const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    resetMembership: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembership.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getMembership.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.membership = action.payload;
        state.error = null;
      })
      .addCase(getMembership.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteMembership.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteMembership.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.membershipDelete = action.payload;
        state.error = null;
      })
      .addCase(deleteMembership.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addMembership.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addMembership.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.membershipAdd = action.payload;
        state.error = null;
      })
      .addCase(addMembership.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editMembership.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editMembership.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.membershipEdit = action.payload;
        state.error = null;
      })
      .addCase(editMembership.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(byIdMembership.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(byIdMembership.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.membershipById = action.payload;
        state.error = null;
      })
      .addCase(byIdMembership.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editMembershipDeal.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editMembershipDeal.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.membershipDealEdit = action.payload;
        state.error = null;
      })
      .addCase(editMembershipDeal.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetMembership } = membershipSlice.actions;
export default membershipSlice.reducer;
