import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { editNotification } from "../../redux/thunk/notificationThunk";
import { resetNotification } from "../../redux/slice/notificationSlice";
import { getByIdNotification } from "../../redux/thunk/notificationThunk";

const EditNotification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [formFieldData, setFormFieldData] = useState({
    name: "",
    price: "",
  });

  const { notificationEdit, notificationById, loading } = useSelector(
    (state) => state.notificationState
  );
  useEffect(() => {
    dispatch(getByIdNotification(id));

    return () => {
      dispatch(resetNotification());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (notificationEdit.success) {
      navigate("/notification", { replace: true });
      dispatch(resetNotification());
    }
  }, [notificationEdit.success, dispatch, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = {
        title: formFieldData.title,
        message: formFieldData.message,
      };

      if (data) {
        dispatch(editNotification({ data: data, id }));
      }
    } catch (err) {}
  };

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Edit Notification </h1>
        <h1 className="addeditblog-blogtext2">
          Here you can Edit your notification
        </h1>
        <form
          className="addeditblog-blogform"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="title" data-after=" *">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Enter notification section ..."
                defaultValue={notificationById?.title}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="price" data-after=" *">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="4"
                placeholder="Notification message here ..."
                defaultValue={notificationById?.message}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Edit Notification "
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditNotification;
