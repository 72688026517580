import { createSlice } from "@reduxjs/toolkit";
import {
  addSection,
  byIdSection,
  deleteSection,
  editSection,
  getSection,
} from "../thunk/sectionThunk";

const initialState = {
  section: [],
  sectionDelete: [],
  sectionById: [],
  sectionAdd: [],
  sectionEdit: [],
  loading: false,
  success: false,
  error: null,
};

const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    resetSection: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSection.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(getSection.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.section = action.payload;
        state.error = null;
      })
      .addCase(getSection.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(deleteSection.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.sectionDelete = action.payload;
        state.error = null;
      })
      .addCase(deleteSection.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(addSection.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(addSection.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.sectionAdd = action.payload;
        state.error = null;
      })
      .addCase(addSection.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(editSection.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(editSection.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.sectionEdit = action.payload;
        state.error = null;
      })
      .addCase(editSection.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      })
      .addCase(byIdSection.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(byIdSection.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.sectionById = action.payload;
        state.error = null;
      })
      .addCase(byIdSection.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error;
      });
  },
});
export const { resetSection } = sectionSlice.actions;
export default sectionSlice.reducer;
