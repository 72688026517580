import { createAsyncThunk } from "@reduxjs/toolkit";
import { doDelete, doGet, doPost, doPut } from "../../axios/instance";
import { toast } from "react-toastify";

export const getVideo = createAsyncThunk("getVideo", async () => {
  try {
    const response = await doGet("/admin/video");
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
export const getByIdVideo = createAsyncThunk("getByIdVideo", async (slug) => {
  try {
    const response = await doGet(`/admin/video/${slug}`);
    if (response.status === 200) {
      return response.data.data[0];
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
export const addVideo = createAsyncThunk("addVideo", async (data) => {
  try {
    const response = await doPost(`/admin/video`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const editVideo = createAsyncThunk(
  "editVideo",
  async ({ data, slug }) => {
    try {
      const response = await doPut(`/admin/video/${slug}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const deleteVideo = createAsyncThunk("deleteVideo", async (slug) => {
  try {
    const response = await doDelete(`/admin/video/${slug}`);
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});

export const addVideoImage = createAsyncThunk(
  "addImage",
  async ({ data, slug }) => {
    try {
      const response = await doPost(`/admin/video/image/${slug}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const addVideoPdf = createAsyncThunk(
  "addPdf",
  async ({ data, slug }) => {
    try {
      const response = await doPost(`/admin/video/pdf/${slug}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const deleteVideoImage = createAsyncThunk(
  "deleteVideoImage",
  async (id) => {
    try {
      const response = await doDelete(`/admin/video/image/${id}`);
      if (response.status === 200) {
        toast.success(response?.data?.message, {
          autoClose: 2000,
        });
        return response.data;
      }
      throw new Error(`Failed with status ${response.status}`);
    } catch (error) {
      toast.error(error.response?.data?.message, {
        autoClose: 2000,
      });
      throw error;
    }
  }
);

export const deleteVideoPdf = createAsyncThunk("deleteVideoPdf", async (id) => {
  try {
    const response = await doDelete(`/admin/video/pdf/${id}`);
    if (response.status === 200) {
      toast.success(response?.data?.message, {
        autoClose: 2000,
      });
      return response.data;
    }
    throw new Error(`Failed with status ${response.status}`);
  } catch (error) {
    toast.error(error.response?.data?.message, {
      autoClose: 2000,
    });
    throw error;
  }
});
