import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import {
  byIdMembership,
  editMembership,
} from "../../redux/thunk/membershipThunk";
import { resetMembership } from "../../redux/slice/membershipSlice";

const EditMembership = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [formFieldData, setFormFieldData] = useState({
    name: "",
    duration: "",
    price: "",
    description: "",
  });

  const { membershipEdit, membershipById, loading } = useSelector(
    (state) => state.membershipState
  );
  useEffect(() => {
    dispatch(byIdMembership(slug));

    return () => {
      dispatch(resetMembership());
    };
  }, [slug, dispatch]);

  useEffect(() => {
    if (membershipEdit.success) {
      navigate(`/membership`);
      dispatch(resetMembership());
    }
  }, [membershipEdit.success, dispatch, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFieldData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = {
        duration: formFieldData?.duration,
        name: formFieldData?.name,
        price: formFieldData?.price,
        description: formFieldData?.description,
      };

      if (data) {
        dispatch(editMembership({ data: data, slug }));
      }
    } catch (err) {}
  };

  return (
    <Layout>
      {loading && <Loader isActive={true} />}
      <div className="addeditblog" style={{ margin: "0 10rem" }}>
        <h1 className="addeditblog-blogtext1">Edit Membership </h1>
        <h1 className="addeditblog-blogtext2">
          Here you can Edit your membership
        </h1>
        <form className="addeditblog-blogform" onSubmit={handleSubmit}>
          <div className="addeditblog-blogform--left">
            <div>
              <label htmlFor="name" data-after=" *">
                Membership Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter membership section ..."
                defaultValue={membershipById?.name}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="price" data-after=" *">
                Membership Price
              </label>
              <input
                type="text"
                id="price"
                name="price"
                placeholder="eg. Rs 8000"
                defaultValue={membershipById.price}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="duration" data-after=" *">
                Membership Duration
              </label>
              <input
                type="text"
                id="duration"
                name="duration"
                placeholder="eg. 2"
                defaultValue={membershipById.duration}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor="description" data-after=" *">
                Membership Description
              </label>
              <textarea
                name="description"
                id="description"
                cols="30"
                rows="4"
                placeholder="Membership description here ..."
                defaultValue={membershipById.description}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <div className="submitbtn">
              <input
                type="submit"
                value="Edit Membership "
                className="secondary-button"
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default EditMembership;
