import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import moment from "moment";

import Layout from "../../components/Layout";
import {
  addVideoImage,
  addVideoPdf,
  deleteVideo,
  deleteVideoImage,
  deleteVideoPdf,
  getByIdVideo,
  getVideo,
} from "../../redux/thunk/videoThunk";
import {
  resetVideo,
  resetVideoById,
  resetVideoImage,
  resetVideoPdf,
} from "../../redux/slice/videoSlice";
import { url } from "../../utils/env";
import Modal from "../../components/Modal";
import { getCourse } from "../../redux/thunk/courseThunk";
import { getSeries } from "../../redux/thunk/seriesThunk";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";

const ListVideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { course_slug, series_slug } = useParams();
  const {
    video,
    videoDelete,
    videoById,
    loading,
    videoImageAdd,
    videoImageDelete,
    videoPdfAdd,
    videoPdfDelete,
  } = useSelector((state) => state.videoState);
  const { course, loading: courseLoading } = useSelector(
    (state) => state.courseState
  );
  const { series, loading: seriesLoading } = useSelector(
    (state) => state.seriesState
  );
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResourceDeleteModal, setShowResourceDeleteModal] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [showPdfModal, setShowPdfModal] = useState(false);

  useEffect(() => {
    if (videoDelete.success) {
      setShowDeleteModal(false);
      navigate(`/${course_slug}/${series_slug}`);
      dispatch(resetVideo());
    }
    dispatch(getVideo());
  }, [videoDelete.success, dispatch, navigate, course_slug, series_slug]);

  useEffect(() => {
    dispatch(getVideo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCourse());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSeries());
  }, [dispatch]);

  useEffect(() => {
    if (showModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showModal]);
  useEffect(() => {
    if (showDeleteModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showDeleteModal]);

  useEffect(() => {
    if (showImagesModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showImagesModal]);

  useEffect(() => {
    if (showPdfModal) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [showPdfModal]);

  const columns = useMemo(
    () => [
      {
        Header: "S.N",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Title",
        accessor: "title",
        headerStyle: {
          width: "300px",
        },
      },
      {
        Header: "Thumbnail",
        accessor: (row) => (
          <div className="tableimage">
            <img src={`${url}/video/${row.image}`} alt="" />
          </div>
        ),
      },
      {
        Header: "Video",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              onClick={() => {
                setShowVideoModal(true);
                dispatch(getByIdVideo(row.slug));
              }}
              className="actionlinks-ride"
            >
              <i className="fa-regular fa-video"></i>
            </div>
          </div>
        ),
      },
      {
        Header: "Video Type",
        accessor: (row) => (
          <div className="statusdiv">
            <span
              style={{
                backgroundColor: getStatusBackgroundColor(row?.flag),
              }}
              className="statusdiv-status"
            >
              {row?.flag}
            </span>
          </div>
        ),
        headerStyle: {
          width: "100px",
        },
      },

      {
        Header: "Published At",
        accessor: (row) => moment(row.created_at).format("MMM Do YYYY"),
        headerStyle: {
          width: "200px",
        },
      },
      {
        Header: "Action",
        accessor: (row) => (
          <div className="actionlinks">
            <div
              onClick={() => {
                setShowModal(true);
                dispatch(getByIdVideo(row.slug));
              }}
              className="actionlinks-view"
            >
              <i className="fa-regular fa-eye"></i>
            </div>

            <Link
              to={`/${course_slug}/${series_slug}/editvideo/${row.slug}`}
              className="actionlinks-edit"
            >
              <i className="fa-regular fa-edit"></i>
            </Link>

            <div
              onClick={() => {
                dispatch(getByIdVideo(row.slug));
                setShowDeleteModal(true);
              }}
              className="actionlinks-delete"
            >
              <i className="fa-regular fa-trash"></i>
            </div>
            <div
              onClick={() => {
                setShowImagesModal(true);
                dispatch(getByIdVideo(row.slug));
              }}
              className="actionlinks-images"
            >
              <i class="fa-regular fa-images"></i>
            </div>

            <div
              onClick={() => {
                setShowPdfModal(true);
                dispatch(getByIdVideo(row.slug));
              }}
              className="actionlinks-pdf"
            >
              <i className="fa-regular fa-file-pdf"></i>
            </div>
          </div>
        ),
        headerStyle: {
          width: "250px",
        },
      },
    ],
    [dispatch, course_slug, series_slug]
  );

  const getStatusBackgroundColor = (flag) => {
    if (flag === "Free") {
      return "#e6602b";
    } else if (flag === "Premium") {
      return "#fb3535";
    }
  };

  const filteredVideo = useMemo(() => {
    return video.filter(
      (item) =>
        item.course_slug === course_slug && item.series_slug === series_slug
    );
  }, [video, course_slug, series_slug]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data: useMemo(() => filteredVideo, [filteredVideo]),
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  const currentCourse = useMemo(() => {
    return course.find((c) => c.slug === course_slug);
  }, [course, course_slug]);

  const courseName = currentCourse ? currentCourse.name : "";

  const currentSeries = useMemo(() => {
    return series.find((c) => c.slug === series_slug);
  }, [series, series_slug]);

  const seresName = currentSeries ? currentSeries.name : "";

  const [videoImage, setVideoImage] = useState({
    file: null,
    dataURL: null,
  });
  const [videoPdf, setVideoPdf] = useState({
    file: null,
    dataURL: null,
  });

  const handleImageChange = (setFunction, event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setFunction({
          file: file,
          dataURL: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddImage = async (event) => {
    event.preventDefault();
    if (!videoImage.file) {
      toast.error("Please Upload a Image");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("course_slug", course_slug);
      formData.append("series_slug", series_slug);
      formData.append("image", videoImage?.file);
      if (formData && videoById?.slug) {
        const slug = videoById?.slug;
        dispatch(addVideoImage({ data: formData, slug }));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (videoImageAdd.success) {
      setVideoImage({ file: null, dataURL: null });
      dispatch(getByIdVideo(videoById?.slug));
      dispatch(resetVideoImage());
    }
  }, [videoImageAdd.success, dispatch, videoById?.slug]);
  useEffect(() => {
    if (videoImageDelete.success) {
      dispatch(getByIdVideo(videoById?.slug));
      dispatch(resetVideoImage());
      setShowResourceDeleteModal(false);
    }
  }, [videoImageDelete.success, dispatch, videoById?.slug]);

  const handleAddPdf = async (event) => {
    event.preventDefault();
    if (!videoPdf.file) {
      toast.error("Please Upload a PDF");
      return;
    }
    try {
      const formData = new FormData();
      formData.append("course_slug", course_slug);
      formData.append("series_slug", series_slug);
      formData.append("pdf", videoPdf?.file);
      if (formData && videoById?.slug) {
        const slug = videoById?.slug;
        dispatch(addVideoPdf({ data: formData, slug }));
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (videoPdfAdd.success) {
      setVideoPdf({ file: null, dataURL: null });
      dispatch(getByIdVideo(videoById?.slug));
      dispatch(resetVideoPdf());
    }
  }, [videoPdfAdd.success, dispatch, videoById?.slug]);
  useEffect(() => {
    if (videoPdfDelete.success) {
      dispatch(getByIdVideo(videoById?.slug));
      dispatch(resetVideoPdf());
      setShowResourceDeleteModal(false);
    }
  }, [videoPdfDelete.success, dispatch, videoById?.slug]);

  return (
    <Layout>
      {(loading || courseLoading || seriesLoading) && (
        <Loader isActive={true} />
      )}
      <div className="listpage">
        <div className="listpage-top">
          <h1>
            {courseName} / {seresName}
          </h1>
          <div className="listpage-top--textlink">
            <h2>
              <span>
                <i className="fa-regular fa-video"></i>
              </span>
              &nbsp;/ Playlist Management
            </h2>
            <div className="listpage-top--textlink_addsetting">
              <Link
                className="secondary-button"
                to={`/${course_slug}/${series_slug}/section`}
              >
                <span>
                  <i className="fa-regular fa-gear"></i>
                </span>
                &nbsp;&nbsp; Manage Section
              </Link>
              <Link
                className="secondary-button"
                to={`/${course_slug}/${series_slug}/addvideo`}
              >
                <span>
                  <i className="fa-regular fa-plus"></i>
                </span>
                &nbsp;&nbsp; Add Video
              </Link>
            </div>
          </div>
        </div>

        <div className="listpage-searchentry">
          <div className="listpage-searchentry--search">
            <input
              type="search"
              placeholder="Search here..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setGlobalFilter(e.target.value);
              }}
            />
            <i className="fa-regular fa-magnifying-glass"></i>
          </div>

          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="listpage-searchentry--pageselect"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={video.length}>All</option>
          </select>
        </div>

        <div className="listpage-tablesection">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} style={column.headerStyle}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <tr>
                  <td colSpan={columns.length} className="notfound">
                    Data not found.
                  </td>
                </tr>
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          {pageSize !== video.length && (
            <div className="listpage-tablesection--pagination">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {"<<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          title={`Video Detail`}
          handleCloseModal={() => {
            setShowModal(false);
            dispatch(resetVideoById());
          }}
        >
          <div className="videodetail" style={{ width: "600px" }}>
            <div className="videodetail-thumbnail">
              <img src={`${url}/video/${videoById?.video_thumbnail}`} alt="" />
            </div>
            <div className="videodetail-top">
              <div className="videodetail-top--image">
                <img src={`${url}/author/${videoById?.author_image}`} alt="" />
              </div>
              <div className="videodetail-top--content">
                <h2>{videoById?.author_name}</h2>
                <h3>Author</h3>
              </div>
            </div>
            <div className="videodetail-about">About this class</div>
            <div
              dangerouslySetInnerHTML={{
                __html: videoById?.description,
              }}
              className="quilldesc"
            />
          </div>
        </Modal>
      )}

      {showVideoModal && videoById && videoById?.video && (
        <Modal
          title={`Video`}
          handleCloseModal={() => {
            setShowVideoModal(false);
            dispatch(resetVideoById());
          }}
        >
          <div style={{ width: "80vw" }}>
            <video width="100%" style={{ height: "70vh" }} controls>
              <source
                src={`${url}/video/${videoById?.video}`}
                type="video/mp4"
              />
            </video>
          </div>
        </Modal>
      )}

      {showDeleteModal && (
        <Modal
          title="Delete Video"
          handleCloseModal={() => setShowDeleteModal(false)}
        >
          <div className="confirmdiv" style={{ width: "400px" }}>
            <i
              className="fa-regular fa-circle-exclamation"
              style={{ background: "none" }}
            ></i>
            <p>Are you sure you want to delete this video?</p>
            <div className="confirmdiv-btns">
              <button
                className="primary-button"
                onClick={() => dispatch(deleteVideo(videoById?.slug))}
              >
                Yes
              </button>
              <button
                className="secondary-button"
                onClick={() => setShowDeleteModal(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showImagesModal && (
        <Modal
          title={`Image Resources`}
          handleCloseModal={() => {
            setShowImagesModal(false);
            dispatch(resetVideoById());
            setVideoImage({ file: null, dataURL: null });
          }}
        >
          <div className="imagedetail" style={{ width: "800px" }}>
            <form action="" onSubmit={handleAddImage}>
              <div>
                <label htmlFor="videoImage">Add Image</label>
                <input
                  type="file"
                  id="videoImage"
                  name="videoImage"
                  accept="image/*"
                  onChange={(e) => handleImageChange(setVideoImage, e)}
                />
                {videoImage?.dataURL && (
                  <img
                    src={videoImage?.dataURL}
                    alt="Selected"
                    style={{
                      width: "100%",
                      height: "250px",
                      margin: "10px 0px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
              <div>
                <input type="submit" value="Add" className="secondary-button" />
              </div>
            </form>

            <table>
              <thead>
                <tr>
                  <th style={{ width: "70px" }}>S.N</th>
                  <th>Image</th>
                  <th style={{ width: "150px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {videoById?.images?.map((value, i) => (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td>
                      <img
                        src={`${url}/image/${value.image}`}
                        alt="video images"
                        style={{
                          height: "300px",
                          width: "100%",
                          objectFit: "contain",
                          cursor: "pointer",
                          padding: "1rem",
                        }}
                        onClick={() =>
                          window.open(`${url}/image/${value.image}`, "_blank")
                        }
                      />
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i
                          className="fa-regular fa-trash"
                          style={{
                            height: "30px",
                            width: "30px",
                            background: "red",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowResourceDeleteModal(true)}
                        ></i>
                        {showResourceDeleteModal && (
                          <Modal
                            title="Delete Image"
                            handleCloseModal={() =>
                              setShowResourceDeleteModal(false)
                            }
                          >
                            <div
                              className="confirmdiv"
                              style={{ width: "400px" }}
                            >
                              <i
                                className="fa-regular fa-circle-exclamation"
                                style={{ background: "none" }}
                              ></i>
                              <p>Are you sure you want to delete this image?</p>
                              <div className="confirmdiv-btns">
                                <button
                                  className="primary-button"
                                  onClick={() =>
                                    dispatch(deleteVideoImage(value.id))
                                  }
                                >
                                  Yes
                                </button>
                                <button
                                  className="secondary-button"
                                  onClick={() =>
                                    setShowResourceDeleteModal(false)
                                  }
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </Modal>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )}

      {showPdfModal && (
        <Modal
          title={`PDF Resources`}
          handleCloseModal={() => {
            setShowPdfModal(false);
            dispatch(resetVideoById());
            setVideoPdf({ file: null, dataURL: null });
          }}
        >
          <div className="imagedetail" style={{ width: "800px" }}>
            <form action="" onSubmit={handleAddPdf}>
              <div>
                <label htmlFor="videoPdf">Add PDF</label>
                <input
                  type="file"
                  id="videoPdf"
                  name="videoPdf"
                  accept=".pdf"
                  onChange={(e) => handleImageChange(setVideoPdf, e)}
                />
                {videoPdf?.dataURL && (
                  <iframe
                    src={videoPdf?.dataURL}
                    alt="Selected"
                    title="pdf"
                    style={{
                      width: "100%",
                      height: "300px",
                      margin: "10px 0px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>
              <div>
                <input type="submit" value="Add" className="secondary-button" />
              </div>
            </form>

            <table>
              <thead>
                <tr>
                  <th style={{ width: "70px" }}>S.N</th>
                  <th>PDF</th>
                  <th style={{ width: "150px" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {videoById?.pdfs?.map((value, i) => (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td>
                      <div
                        onClick={() =>
                          window.open(`${url}/pdf/${value.pdf}`, "_blank")
                        }
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        {value.pdf}
                      </div>
                    </td>

                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i
                          className="fa-regular fa-trash"
                          style={{
                            height: "30px",
                            width: "30px",
                            background: "red",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowResourceDeleteModal(true)}
                        ></i>
                        {showResourceDeleteModal && (
                          <Modal
                            title="Delete PDF"
                            handleCloseModal={() =>
                              setShowResourceDeleteModal(false)
                            }
                          >
                            <div
                              className="confirmdiv"
                              style={{ width: "400px" }}
                            >
                              <i
                                className="fa-regular fa-circle-exclamation"
                                style={{ background: "none" }}
                              ></i>
                              <p>Are you sure you want to delete this pdf?</p>
                              <div className="confirmdiv-btns">
                                <button
                                  className="primary-button"
                                  onClick={() =>
                                    dispatch(deleteVideoPdf(value.id))
                                  }
                                >
                                  Yes
                                </button>
                                <button
                                  className="secondary-button"
                                  onClick={() =>
                                    setShowResourceDeleteModal(false)
                                  }
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </Modal>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default ListVideo;
